import { httpClient } from "../../../utils/HttpClient";

const getFetchUid = async (firebase) => {
  // language -> th, eng //move
  try {
    const result = await httpClient.get(`auth/get-login-detect?uidLogin=${firebase}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getEmailByUsernameFetch = async (value) => {
  // value[ email ] :
  try {
    const result = await httpClient.get(`auth/get-email-by-username?username=${value.username}`);

    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return [];
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getEmailIsOnDbFetch = async (email) => {
  // value[ email ] :
  try {
    const result = await httpClient.get(`auth/get-email-is-on-db?email=${email}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return [];
    }
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getFetchUid,
  getEmailByUsernameFetch,
  getEmailIsOnDbFetch,

  // update

  // insert

  // delete
};
