/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from "react";
import { Row, Col, Pagination, Empty } from "antd";
import { useDimensions } from "../../common/mamager/ScreenManager";
import PredictionRoomItem from "./PredictionRoomItem";

export default function PredictionRoomBoard(props) {
  const { width } = useDimensions();
  const [predictionGroup, setPredictionGroup] = useState({
    data: [],
    pageNumber: 0,
  });

  const [page, setPage] = useState({
    minValue: 0,
    maxValue: 4,
  });

  const handleChange = (value) => {
    if (value <= 1) {
      setPage({
        minValue: 0,
        maxValue: 4,
      });
    } else {
      setPage({
        minValue: page.maxValue,
        maxValue: value * 4,
      });
    }
    window.scrollTo(0, 0);
  };

  function getPredictionFound() {
    return (
      <div style={{ paddingTop: 30, paddingBottom: 60 }}>
        <Empty
          imageStyle={{
            height: 80,
          }}
          style={{ width: "100%" }}
          description={<span style={{ color: "gray" }}>ไม่พบรายการ</span>}
        ></Empty>
      </div>
    );
  }

  useEffect(() => {
    let fillPaymentOrderStatus = props.predictionRoom?.filter(
      (fill) => fill?.payment_order?.paymentStatusId === 1
    );
    setPredictionGroup({
      data: fillPaymentOrderStatus,
      pageNumber: 0,
    });
  }, [props.predictionRoom]);

  // console.log("predictionGroup.data : ", predictionGroup.data)

  return (
    <div style={{ paddingTop: 15 }}>
      <Row style={{ paddingInline: width < 600 ? 0 : 37 }}>
        {predictionGroup.data.length > 0 ? (
          <>
            <Col span={24}>
              <Row>
                {predictionGroup.data &&
                  predictionGroup.data.length > 0 &&
                  predictionGroup.data
                    .slice(page.minValue, page.maxValue)
                    .map((val, index) => (
                      <>
                        <PredictionRoomItem
                          key={index}
                          item={val}
                          index={index + 1}
                          user={props.user}
                        />
                      </>
                    ))}
              </Row>
            </Col>

            <Col span={24} style={{ paddingBottom: 15 }}>
              <center>
                <Pagination
                  defaultCurrent={1}
                  defaultPageSize={4}
                  onChange={handleChange}
                  total={predictionGroup.data.length}
                />
              </center>
            </Col>
          </>
        ) : (
          <Col span={24}>{getPredictionFound()}</Col>
        )}
      </Row>
    </div>
  );
}
