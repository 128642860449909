import React, { useEffect, useState } from "react";
import "./css/index.css";
import { Button, Col, Row } from "antd";
import axios from "axios";
// import parse from "html-react-parser";
import LoadingPage from "../../common/components/loading/page";
import { useNavigate, useParams } from "react-router";
import Footer from "../../common/components/footer";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import Header from "../../common/components/header";
import { useDispatch } from "react-redux";
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action";
import { serverUrl } from "../../constants";
import parse from "html-react-parser";
import {
  detectCourseAlreadyPurchased,
  detectCourseExpired,
} from "../../common/mamager/DetectManager";
const CourseDetail = ({ firebase, user, authorized }) => {
  const [courseDetail, setCourseDetail] = useState();

  //Comment set isLoading to false
  const [isLoading, setIsLoading] = useState(true);
  const [courseStatus, setCourseStatus] = useState("NOT_PURCHASED");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getProductDetail = async () => {
      try {
        setIsLoading(true);

        // Fetch product detail
        const res = await axios.get(
          `${serverUrl}/api/v1/course/get-course-by-id/${id}`
        );

        const { data } = res;

        if (data.isSuccess) {
          // console.log(data);
          setCourseDetail(data.formData);
        }

        const responseDetectCoursePurchased =
          await detectCourseAlreadyPurchased(
            firebase.auth().currentUser?.uid,
            id
          );

        const responseDetectCourseExpired = await detectCourseExpired(
          firebase.auth().currentUser?.uid,
          id
        );

        if (responseDetectCourseExpired === "EXPIRED") {
          setCourseStatus("EXPIRED");
        } else if (
          responseDetectCoursePurchased === "AWAITING_PAYMENT_APPROVAL"
        ) {
          setCourseStatus("AWAITING_PAYMENT_APPROVAL");
        } else if (responseDetectCoursePurchased === "ALREADY_PURCHASED") {
          setCourseStatus("ALREADY_PURCHASED");
        } else {
          setCourseStatus("NOT_PURCHASED");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching course details:", error);
      }
    };
    getProductDetail();
  }, []);
  const handleBuyProduct = () => {
    window.scrollTo(0, 0);
    if (courseStatus === "ALREADY_PURCHASED") {
      navigate("/classroom-course-study?course-id=" + id);
    } else {
      navigate("/payment");
      const course = [courseDetail];
      dispatch(setBasketOrderStateToSuccess(course));
    }
  };

  if (isLoading) return <LoadingPage loading={isLoading} />;
  return (
    <div className="course-detail">
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={courseDetail.title} />
      <section className="detail">
        <div className="main-bg">
          <div className="container">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <Col xs={24} md={24} lg={12}>
                <img
                  src={`${serverUrl}/${courseDetail.imgCoverUrl}`}
                  width={426}
                  height={467}
                  alt={"Witte-bangkok Product"}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={12}>
                <div className="detail-text">
                  <div className="price">
                    <div className="title">ราคา</div>
                    <div>
                      <span
                        style={{
                          textDecoration:
                            courseDetail?.discount > 0
                              ? "line-through"
                              : "none",
                          textDecorationColor:
                            courseDetail?.discount > 0
                              ? "#FF0000"
                              : "transparent",
                          color:
                            courseDetail?.discount > 0 ? "#F3BB02" : "#FEE285",
                        }}
                      >
                        {new Intl.NumberFormat("en-US").format(
                          courseDetail?.price
                        )}
                      </span>
                      <span> บาท</span>
                    </div>
                  </div>

                  {courseDetail?.discount > 0 && (
                    <div className="price">
                      <div className="title">ลดเหลือ</div>
                      <div className="highlight">
                        {new Intl.NumberFormat("en-US").format(
                          courseDetail?.price - courseDetail?.discount
                        )}
                        <span style={{ color: "white" }}> บาท</span>
                      </div>
                    </div>
                  )}

                  <div style={{ display: "grid" }}>
                    {courseDetail?.promotion && (
                      <div style={{ color: "white", fontSize: 24 }}>
                        <strong>โปรโมชั่น</strong>
                        <br />
                        <div style={{ fontSize: 20 }}>
                          {parse(courseDetail?.promotion)}
                        </div>
                      </div>
                    )}
                  </div>

                  {courseStatus === "NOT_PURCHASED" && (
                    <Button
                      size="large"
                      type="primary"
                      style={{ marginTop: 24 }}
                      onClick={handleBuyProduct}
                    >
                      สังซื้อเลย
                    </Button>
                  )}

                  {courseStatus === "AWAITING_PAYMENT_APPROVAL" && (
                    <>
                      <Button
                        size="large"
                        type="primary"
                        disabled={true}
                        style={{
                          marginTop: 24,
                          backgroundColor: "#C0C0C0",
                          color: "white",
                        }}
                        onClick={handleBuyProduct}
                      >
                        สังซื้อเลย
                      </Button>
                      <p style={{ fontSize: 24 }}>
                        <strong>คุณได้ซื้อคอร์สนี้เเล้ว!</strong>
                      </p>
                      <p style={{ fontSize: 24 }}>
                        ขณะนี้ อยู่ระหว่างตรวจสอบการชำระเงิน
                      </p>
                    </>
                  )}

                  {courseStatus === "ALREADY_PURCHASED" && (
                    <>
                      <Button
                        size="large"
                        type="primary"
                        color="#C0C0C0"
                        style={{ marginTop: 24 }}
                        onClick={handleBuyProduct}
                      >
                        เข้าห้องเรียน
                      </Button>
                    </>
                  )}

                  {courseStatus === "EXPIRED" && (
                    <>
                      <Button
                        size="large"
                        type="primary"
                        color="#C0C0C0"
                        style={{ marginTop: 24 }}
                        onClick={handleBuyProduct}
                      >
                        ต่ออายุการใช้งาน
                      </Button>
                      <p style={{ fontSize: 24 }}>
                        <strong>คอร์สนี้หมดอายุเเล้ว!</strong>
                      </p>
                      <p style={{ fontSize: 24 }}>
                        หากต้องการใช้งานอีกครั้ง กรุณาต่ออายุการใช้งาน
                      </p>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="container">
          {courseDetail?.detail && (
            <div className="suggest">
              <strong>คำแนะนำ</strong>
              <br />
              <div style={{ fontSize: 20 }}>{parse(courseDetail?.detail)}</div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CourseDetail;
