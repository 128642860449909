import { useEffect } from "react";
import { Image, Card, Row, Col } from "antd";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import { useDimensions } from "../../common/mamager/ScreenManager";
import LoginItem from "./LoginItem";
import "../login/css/index.css";

export default function Login({ authorized, firebase, user }) {
  const { width } = useDimensions();

  useEffect(() => {}, [authorized]);

  return (
    <>
      <Header
        firebase={firebase}
        authorized={authorized}
        user={user}
        btnAutnActive={false}
      />

      <article>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 37,
            paddingBottom: 24,
            paddingInline: width < 470 ? 16 : null,
          }}
        >
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col
              span={24}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="image-wrapper">
                <div className="image-login">
                  <img
                    src="/assets/images/page/login/logo.png"
                    width={402}
                    height={306}
                    alt={"Witte-bangkok Login"}
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </Col>
            <Col
              span={24}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                style={{
                  maxWidth: 400,
                  borderRadius: 20,
                  boxShadow: "2px 2px 16px 5px rgba(208, 216, 243, 1)",
                }}
              >
                <div style={{ width: "80%", marginInline: "auto" }}>
                  <LoginItem firebase={firebase} authorized={authorized} />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </article>

      <Footer />
    </>
  );
}
