import { httpClient } from "../../../utils/HttpClient"

const getCourseByIdFetch = async (courseId) => {
    try {
        const result = await httpClient.get(`course/get-course-by-id/${courseId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVideoCourseByIdFetch = async (id) => {
    try {
        const result = await httpClient.get(`classroom/get-video-course-by-id/${id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseByIdFetch,
    getVideoCourseByIdFetch
}