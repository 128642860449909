import { httpClient } from "../../../utils/HttpClient"

const insertSuggestionFetch = async (value) => {
    try {
        const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
        const queryParams = new URLSearchParams(filteredValue).toString()
        const result = await httpClient.post(`suggestion/insert-suggestion?${queryParams}`)
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const lineNotifySuggestion = async (value) => {
    try {
        const result = await httpClient.post("line/line-notify-suggestion", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get

    // insert
    insertSuggestionFetch,

    // update

    // delete

    lineNotifySuggestion
}