/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Header from "../../common/components/header"
import ClassroomBoard from "./components/ClassroomBoard"
import { getClassroomByIdFetch } from "./API"
import { detectSignIn } from "../../common/mamager/DetectManager"
import LoadingPage from "../../common/components/loading/page"
import Footer from "../../common/components/footer"
import BreadcrumbPage from "../../common/components/breadcrumbPage"
import ExamRoomBoard from "../exam/components/examRoom/ExamRoomBoard"
import { getExamRoomAllFetch } from "../exam/API"
import { Col, Divider, Row } from "antd"

export default function Classroom(props) {
    const navigate = useNavigate()

    const [classroom, setClassroom] = useState([])
    const [examRoom, setExamRoom] = useState([])

    const [loading, setLoading] = useState(false)

    const getClassroomById = async () => {
        setLoading(true)

        if (props?.user?.uid) {
            const result = await getClassroomByIdFetch(props?.user?.uid)
            // console.log("getClassroomByIdFetch : ", result)
            setClassroom(result)
        }

        setLoading(false)
    }

    const getExamRoomById = async () => {
        setLoading(true)

        if (props?.user?.uid) {
            const result = await getExamRoomAllFetch({ uid: props?.user?.uid })
            // console.log("getExamRoomAllFetch : ", result)
            setExamRoom(result)
        }

        setLoading(false)
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    const getBaseApi = async () => {
        await detectLogin()
        await getClassroomById()
        await getExamRoomById()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Header firebase={props.firebase} authorized={props.authorized} user={props.user} />
            <BreadcrumbPage pageCurrentName={"คอร์สของฉัน"} />

            <article>
                <div style={{ backgroundColor: "#F5F5F5" }}>
                    {!loading ?
                        <Row>
                            <Col span={24}>
                                <ClassroomBoard classroom={classroom} user={props.user} />
                            </Col>
                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={24}>
                                <ExamRoomBoard examRoom={examRoom} user={props.user} />
                            </Col>
                        </Row>
                        :
                        <LoadingPage loading={loading} />
                    }
                </div>
            </article>

            <Footer />
        </>
    )
}
