import { httpClient } from "../../../utils/HttpClient"

const getPaymentOrdersByIdFetch = async (uid) => {
    try {
        const result = await httpClient.get(`payment/get-payment-orders-by-id?uid=${uid}`)
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getPaymentAccountFetch = async () => {
    try {
        const result = await httpClient.get(``)
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const insertPaymentOrderFetch = async (value) => {
    try {
        const result = await httpClient.post(`payment/insert-payment-order`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentOrderByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`payment/update-payment-order-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentAccountByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(`payment/update-payment-account-by-id`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const lineNotifyPaymentOrder = async (value) => {
    try {
        const result = await httpClient.post("line/line-notify-payment-order", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPaymentOrdersByIdFetch,
    getPaymentAccountFetch,

    // insert
    insertPaymentOrderFetch,

    // update
    updatePaymentOrderByIdFetch,
    updatePaymentAccountByIdFetch,

    // delete
    lineNotifyPaymentOrder
}