import { httpClient } from "../../../utils/HttpClient"

const getFeedbackByIdFetch = async (uid, feedbackCategoryId) => {
    try {
        const result = await httpClient.get(`feedback/get-feedback-by-id?uid=${uid}&feedbackCategoryId=${feedbackCategoryId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertFeedbackFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(`feedback/insert-feedback`, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getFeedbackByIdFetch,

    // insert
    insertFeedbackFetch

    // update

    // delete
    
}