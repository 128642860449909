import { httpClient } from "../../../utils/HttpClient";

const getPageHomeFetch = async () => {
  try {
    const result = await httpClient.get(`home/get-page-home`);

    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getBlogAllFetch = async () => {
  try {
    const result = await httpClient.get(`blog/get-blog-all`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getBannerAllFetch = async () => {
  try {
    const result = await httpClient.get(`banner/get-banner-all`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getPreviewProductVideoAllFetch = async () => {
  try {
    const result = await httpClient.get(
      `preview_product/get-preview-product-all`
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getPageHomeFetch,
  getBlogAllFetch,
  getBannerAllFetch,
  getPreviewProductVideoAllFetch,

  // insert

  // update

  // delete
};
