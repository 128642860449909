/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { Layout, Row, Col, Modal, Divider, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDimensions } from "./../../common/mamager/ScreenManager";
import CourseOverview from "./components/overview";
import CourseVideo from "./components/video";
import Header from "./../../common/components/header";
import Footer from "./../../common/components/footer";
import LoadingPage from "./../../common/components/loading/page";
import { getCourseByIdFetch, getVideoCourseByIdFetch } from "./API";
import Video from "../../common/components/video";
import parse from "html-react-parser";
import {
  detectCourseAlreadyPurchased,
  detectCourseExpired,
  detectSignIn,
} from "../../common/mamager/DetectManager";
import firebase from "../../config/firebase-config";
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action";
import { useDispatch } from "react-redux";

const { Content } = Layout;

export default function ClassroomStudy(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const courseId = params.get("course-id");

  const { width } = useDimensions();

  const [course, setCourse] = useState(null);
  const [videoCourse, setVideoCourse] = useState([]);
  const [videoCourseCurrent, setVideoCourseCurrent] = useState();

  const [courseStatus, setCourseStatus] = useState("ALREADY_PURCHASED");

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleVideoCourseCurrent = (value) => {
    setVideoCourseCurrent(value);
  };

  const genCourseOverview = () => {
    return (
      <CourseOverview course={course} courseOwner={course} user={props.user} />
    );
  };

  const genCourseVideoList = () => {
    return (
      <CourseVideo
        videoCourse={videoCourse}
        course={course}
        handleVideoCourseCurrent={handleVideoCourseCurrent}
      />
    );
  };

  const detectLogin = async () => {
    // detect login
    const isLogin = await detectSignIn();
    if (!isLogin) {
      navigate("/login");
    }
  };

  const detectAccessToClassroom = async () => {
    // const result = await detectCourseAlreadyPurchased(props.user.uid, courseId)
    // // console.log("detectCourseAlreadyPurchased : ", result)
    // if (result.isReady) {
    //     navigate("/login")
    // }
  };

  const getCourseById = async () => {
    let result = await getCourseByIdFetch(courseId);
    setCourse(result ?? null);
  };

  const getVideoCourseById = async (isCurrent, isDefaultPercentWatch) => {
    let result = await getVideoCourseByIdFetch(courseId);
    setVideoCourseCurrent(result[0]);
    setVideoCourse(result ?? []);
  };

  const getBaseApi = async () => {
    setLoading(true);

    await detectLogin();
    await detectAccessToClassroom();
    await getCourseById();
    await getVideoCourseById();

    const responseDetectPurchased = await detectCourseAlreadyPurchased(
      firebase.auth().currentUser?.uid,
      courseId
    );

    const responseDetectExpired = await detectCourseExpired(
      firebase.auth().currentUser?.uid,
      courseId
    );

    if (responseDetectExpired === "EXPIRED") {
      setCourseStatus("EXPIRED");
    } else if (responseDetectPurchased === "AWAITING_PAYMENT_APPROVAL") {
      setCourseStatus("AWAITING_PAYMENT_APPROVAL");
    } else if (responseDetectPurchased === "ALREADY_PURCHASED") {
      setCourseStatus("ALREADY_PURCHASED");
    } else {
      setCourseStatus("NOT_PURCHASED");
    }

    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <>
      <Header
        firebase={props.firebase}
        authorized={props.authorized}
        user={props.user}
      />

      <article>
        {!loading ? (
          <Content style={{ backgroundColor: "#F5F5F5" }}>
            <div
              style={
                width >= 1500
                  ? { paddingLeft: "5%", paddingRight: "5%" }
                  : { paddingLeft: "0%", paddingRight: "0%" }
              }
            >
              {courseStatus !== "AWAITING_PAYMENT_APPROVAL" &&
                courseStatus !== "EXPIRED" &&
                courseStatus !== "NOT_PURCHASED" && (
                  <Row>
                    <Col xs={24} lg={16} style={{ backgroundColor: "#0c0c0c" }}>
                      <div
                        style={{
                          display: "grid",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          {width < 992 ? (
                            <div style={{ float: "none" }}>
                              {genCourseOverview()}
                            </div>
                          ) : (
                            []
                          )}
                        </div>

                        <div>
                          <Video
                            url={videoCourseCurrent?.videoUrl}
                            title={videoCourseCurrent?.title}
                            height={"100%"}
                            width={"100%"}
                          />
                        </div>

                        <div>
                          {width < 992 ? (
                            <div style={{ float: "none" }}>
                              {genCourseOverview()}
                            </div>
                          ) : (
                            []
                          )}
                        </div>

                        <div style={{ color: "white", padding: 12 }}>
                          <label style={{}}>รายละเอียดคอร์ส</label>
                          {course?.detail ? parse(course?.detail) : []}
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} lg={8} style={{ backgroundColor: "#444444" }}>
                      <Row>
                        <Col span={24}>
                          {width >= 992 ? (
                            <div style={{ float: "none" }}>
                              {genCourseOverview()}
                            </div>
                          ) : (
                            []
                          )}
                        </Col>

                        <Col span={24}>{genCourseVideoList()}</Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              <Modal
                title={
                  <div>
                    <label style={{ fontWeight: 700, fontSize: 20 }}>
                      {courseStatus === "AWAITING_PAYMENT_APPROVAL" &&
                        "คอร์สอยู่ระหว่างตรวจสอบชำระเงิน"}
                      {courseStatus === "EXPIRED" && "คอร์สนี้หมดอายุเเล้ว"}
                      {courseStatus === "NOT_PURCHASED" &&
                        "คอร์สนี้ยังไม่ได้ชำระเงิน"}
                    </label>
                    <Divider />
                  </div>
                }
                maskStyle={{
                  backgroundColor: "rgba(0, 0, 0, 0.9)", // Dark transparent background for modal overlay
                }}
                centered
                open={
                  courseStatus === "AWAITING_PAYMENT_APPROVAL" ||
                  courseStatus === "EXPIRED" ||
                  courseStatus === "NOT_PURCHASED"
                }
                footer={null}
                closable={false}
                width={700}
              >
                <Row gutter={[16, 12]}>
                  <Col span={24} style={{ fontSize: 20 }}>
                    {courseStatus === "AWAITING_PAYMENT_APPROVAL" &&
                      "โปรดกลับเข้ามาใหม่อีกครั้งภายหลัง"}
                    {courseStatus === "EXPIRED" &&
                      "หากต้องการใช้งานอีกครั้ง กรุณาต่ออายุการใช้งาน"}
                    {courseStatus === "NOT_PURCHASED" &&
                      "โปรดชำระเงินก่อนเข้าเรียน!"}
                  </Col>
                  <Col span={24}>
                    <Button
                      type="default"
                      style={{ color: "#F3BB02", borderColor: "#F3BB02" }}
                      onClick={() => navigate("/")}
                    >
                      ไปหน้าหลัก
                    </Button>
                    {courseStatus === "EXPIRED" && (
                      <Button
                        type="primary"
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                          navigate("/payment");
                          const _course = [course];
                          dispatch(setBasketOrderStateToSuccess(_course));
                        }}
                      >
                        ต่ออายุการใช้งาน
                      </Button>
                    )}
                    {courseStatus === "NOT_PURCHASED" && (
                      <Button
                        type="primary"
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                          navigate("/payment");
                          const _course = [course];
                          dispatch(setBasketOrderStateToSuccess(_course));
                        }}
                      >
                        สังซื้อเลย
                      </Button>
                    )}
                  </Col>
                </Row>
              </Modal>
            </div>
          </Content>
        ) : (
          <LoadingPage loading={loading} />
        )}
      </article>

      <Footer />
    </>
  );
}
