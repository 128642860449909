import React from "react";
// import { Breadcrumb } from 'antd';
import { useDimensions } from "../../mamager/ScreenManager";

export default function BreadcrumbPage(props) {
  const { width } = useDimensions();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // height: 60,
        backgroundImage: `url(/assets/images/bread-crumb/bg.jpg)`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: "white",

        paddingBlock: 24,
      }}
    >
      <div style={{ width: "min(90%, 75rem)", marginInline: "auto" }}>
        <label style={{ fontSize: 48, fontWeight: "bolder" }}>
          {props.pageCurrentName}
        </label>
      </div>

      <div>
        {/* <Breadcrumb
                    itemColor="white"
                    lastItemColor="white"
                    linkColor="white"
                    linkHoverColor="white"
                    separatorColor="white"

                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <a href="">Application Center</a>,
                        },
                        {
                            title: <a href="">Application List</a>,
                        },
                        {
                            title: 'An Application',
                        },
                    ]}
                /> */}
      </div>
    </div>
  );
}
