import { httpClient } from "../../../utils/HttpClient"

const updateUser = async (value) => { // Done
    try {
        const result = await httpClient.put(`user/update-users`, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get

    // update
    updateUser

    // insert
}