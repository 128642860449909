import React, { useEffect, useState } from "react";
import "./css/index.css";

import { Button, Col, Row } from "antd";
import axios from "axios";
// import parse from "html-react-parser";
import LoadingPage from "../../common/components/loading/page";
import { useNavigate, useParams } from "react-router";
import Footer from "../../common/components/footer";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import Header from "../../common/components/header";
import { useDispatch } from "react-redux";
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action";
import { serverUrl } from "../../constants";
import parse from "html-react-parser";
import {
  detectPredictionAlreadyPurchased,
  detectPredictionExpired,
} from "../../common/mamager/DetectManager";
const PredictionDetail = ({ firebase, user, authorized }) => {
  const [productDetail, setProductDetail] = useState();

  //Comment set isLoading to false
  const [isLoading, setIsLoading] = useState(true);
  const [predictionStatus, setPredictionStatus] = useState("NOT_PURCHASED");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const getProductDetail = async () => {
      try {
        setIsLoading(true);

        // Fetch product detail
        const res = await axios.get(
          `${serverUrl}/api/v1/prediction/get-prediction-by-id/${id}`
        );

        const { data } = res;

        if (data.isSuccess) {
          setProductDetail(data.formData);
        }

        const responseDetectPredictionPurchased =
          await detectPredictionAlreadyPurchased(
            firebase.auth().currentUser?.uid,
            id
          );

        const responseDetectPredictionExpired = await detectPredictionExpired(
          firebase.auth().currentUser?.uid,
          id
        );

        if (responseDetectPredictionExpired === "EXPIRED") {
          setPredictionStatus("EXPIRED");
        } else if (
          responseDetectPredictionPurchased === "AWAITING_PAYMENT_APPROVAL"
        ) {
          setPredictionStatus("AWAITING_PAYMENT_APPROVAL");
        } else if (responseDetectPredictionPurchased === "ALREADY_PURCHASED") {
          setPredictionStatus("ALREADY_PURCHASED");
        } else {
          setPredictionStatus("NOT_PURCHASED");
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching prediction details:", error);
      }
    };
    getProductDetail();
  }, []);

  const handleBuyProduct = () => {
    window.scrollTo(0, 0);
    if (predictionStatus === "ALREADY_PURCHASED") {
      navigate("/prediction-open?prediction-id=" + id);
    } else {
      navigate("/payment");
      const prediction = [productDetail];
      dispatch(setBasketOrderStateToSuccess(prediction));
    }
  };
  if (isLoading) return <LoadingPage loading={isLoading} />;
  return (
    <div className="prediction-detail">
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={productDetail.title} />
      <section className="detail">
        <div className="main-bg">
          <div className="container">
            <Row
              style={{
                justifyContent: "center",
              }}
            >
              <Col xs={24} md={24} lg={12}>
                <img
                  src={`${serverUrl}/${productDetail.imgCoverUrl}`}
                  width={426}
                  height={467}
                  alt={"Witte-bangkok Product"}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </Col>
              <Col xs={24} md={24} lg={12}>
                <div className="detail-text">
                  <div className="price">
                    <div className="title">ราคา</div>
                    <div>
                      <span
                        style={{
                          textDecoration:
                            productDetail?.discount > 0
                              ? "line-through"
                              : "none",
                          textDecorationColor:
                            productDetail?.discount > 0
                              ? "#FF0000"
                              : "transparent",
                          color:
                            productDetail?.discount > 0 ? "#F3BB02" : "#FEE285",
                        }}
                      >
                        {new Intl.NumberFormat("en-US").format(
                          productDetail?.price
                        )}
                      </span>
                      <span> บาท</span>
                    </div>
                  </div>

                  {productDetail?.discount > 0 && (
                    <div className="price">
                      <div className="title">ลดเหลือ</div>
                      <div className="highlight">
                        {new Intl.NumberFormat("en-US").format(
                          productDetail?.price - productDetail?.discount
                        )}
                        <span style={{ color: "white" }}> บาท</span>
                      </div>
                    </div>
                  )}

                  <div style={{ display: "grid" }}>
                    {productDetail?.promotion && (
                      <div style={{ color: "white", fontSize: 24 }}>
                        <strong>โปรโมชั่น</strong>
                        <br />
                        <div style={{ fontSize: 20 }}>
                          {parse(productDetail?.promotion)}
                        </div>
                      </div>
                    )}
                  </div>

                  {predictionStatus === "NOT_PURCHASED" && (
                    <Button
                      size="large"
                      type="primary"
                      style={{ marginTop: 24 }}
                      onClick={handleBuyProduct}
                    >
                      สังซื้อเลย
                    </Button>
                  )}

                  {predictionStatus === "AWAITING_PAYMENT_APPROVAL" && (
                    <>
                      <Button
                        size="large"
                        type="primary"
                        disabled={true}
                        style={{
                          marginTop: 24,
                          backgroundColor: "#C0C0C0",
                          color: "white",
                        }}
                        onClick={handleBuyProduct}
                      >
                        สังซื้อเลย
                      </Button>
                      <p style={{ fontSize: 24 }}>
                        <strong>คุณได้ซื้อโหรนี้เเล้ว!</strong>
                      </p>
                      <p style={{ fontSize: 24 }}>
                        ขณะนี้ อยู่ระหว่างตรวจสอบการชำระเงิน
                      </p>
                    </>
                  )}

                  {predictionStatus === "ALREADY_PURCHASED" && (
                    <>
                      <Button
                        size="large"
                        type="primary"
                        color="#C0C0C0"
                        style={{ marginTop: 24 }}
                        onClick={handleBuyProduct}
                      >
                        เข้าห้องเรียน
                      </Button>
                    </>
                  )}

                  {predictionStatus === "EXPIRED" && (
                    <>
                      <Button
                        size="large"
                        type="primary"
                        color="#C0C0C0"
                        style={{ marginTop: 24 }}
                        onClick={handleBuyProduct}
                      >
                        ต่ออายุการใช้งาน
                      </Button>
                      <p style={{ fontSize: 24 }}>
                        <strong>โหรนี้หมดอายุเเล้ว!</strong>
                      </p>
                      <p style={{ fontSize: 24 }}>
                        หากต้องการใช้งานอีกครั้ง กรุณาต่ออายุการใช้งาน
                      </p>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="container">
          {productDetail?.detail && (
            <div className="suggest">
              <strong>คำแนะนำ</strong>
              <br />
              <div style={{ fontSize: 20 }}>{parse(productDetail?.detail)}</div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PredictionDetail;
