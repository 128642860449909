import React, { useEffect, useState } from "react";
import "./css/index.css";
import { Button } from "antd";
import { getPageContactFetch } from "./API";
const YoutubeContact = () => {
  const [contactData, setContactData] = useState();
  const getContactData = async () => {
    const res = await getPageContactFetch();
    setContactData(res);
  };

  useEffect(() => {
    getContactData();
  }, []);

  function getChannelName(url) {
    const regex = /https:\/\/(www\.)?youtube\.com\/@([^\/?]+)/;
    const match = url?.match(regex);

    if (match && match[2]) {
      return match[2]; // Return the channel name after '@'
    }
    return "";
  }
  return (
    <div className="youtube">
      <div className="container">
        <p style={{ fontSize: 24 }}>
          <span style={{ fontWeight: "bolder", fontSize: 24 }}>ตอนนี้!</span>{" "}
          อ่านกรรมจากผังดาว มีช่อง{" "}
          <span style={{ color: "red", fontWeight: "bolder", fontSize: 24 }}>
            YouTube
          </span>{" "}
          เเล้ว เข้าชมได้ที่นี่!
        </p>
        <div className="contact">
          <div className="left">
            <img
              src="../../assets/images/page/page_home/youtube_logo.png"
              alt={"Witte-bangkok Youtube"}
            />
            <span>@{getChannelName(contactData?.youtube)}</span>
          </div>
          <Button
            type="primary"
            style={{
              alignSelf: "stretch",
              height: "unset !important",
              borderRadius: "0px 10px 10px 0px",
            }}
            onClick={() => window.open(contactData?.youtube)}
          >
            คลิกเลย!
          </Button>
        </div>
      </div>
    </div>
  );
};

export default YoutubeContact;
