/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react"
import Header from "../../common/components/header"
import Footer from "../../common/components/footer"
import { useDimensions } from "../../common/mamager/ScreenManager"
import { Button, Row, Col, Divider, Form, Input, Upload } from "antd"
import { insertSuggestionFetch, lineNotifySuggestion } from "./API"
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { Notification } from "../../common/components/notification"
import { apiServerUrl, serverUrl } from "../../constants"
import Video from "../../common/components/video"
import BreadcrumbPage from "../../common/components/breadcrumbPage"
import { Content } from "antd/lib/layout/layout"
import moment from "moment"
import { detectSignIn } from "../../common/mamager/DetectManager"
// import { useNavigate } from "react-router-dom"

const { TextArea } = Input;

const Suggestion = ({ authorized, firebase, user }) => {
  const { width } = useDimensions()
  const [form] = Form.useForm()

  // const navigate = useNavigate()

  const [isLogin, setIsLogin] = useState()

  const [inquiryVideo, setInquiryVideo] = useState({
    loading: false,
    videoUrl: null,
  });

  const [inquiryImage, setInquiryImage] = useState({
    loading: false,
    imageUrl: null,
  });

  const optionInquiryVideo = {
    name: "file",
    action: `${apiServerUrl}/upload/file`,
    data: {
      bucket: "witte-bangkok",
      folder: "suggestion/video"
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length > 0) {
          setInquiryVideo({
            videoUrl: info.file.response.url,
            loading: false,
          });
        }
      } else {
        setInquiryVideo({
          loading: true,
          videoUrl: inquiryVideo.videoUrl,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const optionInquiryImage = {
    name: "file",
    action: `${apiServerUrl}/upload/file`,
    data: {
      bucket: "witte-bangkok",
      folder: "suggestion/image"
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length > 0) {
          setInquiryImage({
            imageUrl: info.file.response.url,
            loading: false,
          });
        }
      } else {
        setInquiryImage({
          loading: true,
          imageUrl: inquiryImage.imageUrl,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const onFinish = async (values) => {
    let uid = user?.uid ?? null;
    let message = values.message ? values.message : null;
    let videoUrl = inquiryVideo.videoUrl ? inquiryVideo.videoUrl : null;
    let imageUrl = inquiryImage.imageUrl ? inquiryImage.imageUrl : null;

    const obj = { uid, message, imageUrl, videoUrl };

    const result = await insertSuggestionFetch(obj);
    if (result?.isSuccess) {
      Notification("success", "เเจ้งเตือน!", "ส่งเเบบสอบถามสำเร็จ");
      form.resetFields();
      setInquiryVideo({
        loading: false,
        videoUrl: null,
      });
      setInquiryImage({
        loading: false,
        imageUrl: null,
      });

      let objLineNotify = {
        suggestionMassage: message,
        suggestionDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      }
      await lineNotifySuggestion(objLineNotify)
    } else {
      Notification(
        "error",
        "เเจ้งเตือน!",
        "ไม่สามารถส่งเเบบสอบถามได้ โปรดลองอีกครั้ง"
      );
    }
  };

  const detectLogin = async () => {
    // detect login
    const result = await detectSignIn()
    if (!result) {
      setIsLogin(false)
      // navigate("/login")
    } else {
      setIsLogin(true)
    }
  }

  const getBaseApi = async () => {
    await detectLogin()
  }

  useEffect(() => {
    getBaseApi()
  }, [])

  // console.log("img --- : ", `${serverUrl}/${inquiryImage.imageUrl}`)
  // console.log("vdo --- : ", inquiryVideo.videoUrl)

  return (
    <>
      <Header />
      <BreadcrumbPage pageCurrentName={"สอบถาม หรือส่งคำเสนอเเนะ"} />
      <Content style={{ backgroundColor: "#D9D9D9" }}>

        <div
          style={{
            backgroundColor: "white",
            maxWidth: 1000,
            marginInline: "auto",
            paddingBottom: 74,
            paddingTop: 38,
          }}
        >
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: width > 925 ? "15%" : 12,
              paddingRight: width > 925 ? "15%" : 12,
              paddingTop: 24,
            }}
          >
            {isLogin ?
              <div
                style={{
                  display: "grid",
                  alignItems: "flex-start",
                  paddingBottom: 32,
                }}
              >
                <label
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    paddingBottom: 12,
                    textAlign: "center",
                  }}
                >
                  กรอกแบบฟอร์มสอบถามข้อมูล หรือส่งคำเสนอเเนะฟรี
                </label>
                <Form
                  form={form}
                  name="validateOnly"
                  layout="vertical"
                  autoComplete="off"
                  onFinish={onFinish}
                >
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Form.Item
                        name="message"
                        label="ข้อความ/MESSAGE"
                        rules={[
                          { required: true, message: "กรุณากรอกข้อความ" },
                        ]}
                      >
                        <TextArea autoSize={{ minRows: 6, maxRows: 8 }} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} xl={12}>
                      <div style={{ display: "grid" }}>
                        <label style={{ paddingBottom: 6 }}>
                          เเนบวิดีโอ (ไม่บังคับ)
                        </label>
                        {inquiryVideo?.videoUrl ? (
                          <div
                            style={{
                              backgroundColor: "black",
                              borderRadius: 8,
                            }}
                          >
                            <Video
                              url={inquiryVideo.videoUrl}
                              title={""}
                              height={"100%"}
                              width={"100%"}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              maxWidth: 350,
                              height: 236,
                              borderRadius: 8,
                              backgroundColor: "#F9F9F9",
                              border: "2px solid #EAEAEA",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <img
                              style={{ width: 119, height: 119 }}
                              src={`./assets/images/page/suggestion/df-vdo.png`}
                              alt={"Witte-bangkok Suggestion"}
                            />
                          </div>
                        )}
                        <div style={{ paddingTop: 12 }}>
                          <Upload
                            {...optionInquiryVideo}
                            accept=".mp4"
                            style={{ width: "100%" }}
                            maxCount={1}
                            showUploadList={{ showRemoveIcon: false }}
                          >
                            <Button
                              type="default"
                              style={{ width: "100%" }}
                              icon={
                                inquiryVideo.loading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <UploadOutlined />
                                )
                              }
                            >
                              อัพโหลดวิดีโอ
                            </Button>
                          </Upload>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={12} xl={12}>
                      <div style={{ display: "grid" }}>
                        <label style={{ paddingBottom: 6 }}>
                          เเนบรูปภาพ (ไม่บังคับ)
                        </label>
                        {inquiryImage?.imageUrl ? (
                          <img
                            style={{
                              borderRadius: 8,
                              maxHeight: 207,
                              border: "1px solid #EEEEEE",
                            }}
                            src={`${serverUrl}/${inquiryImage.imageUrl}`}
                            alt={"Witte-bangkok Suggestion"}
                          />
                        ) : (
                          <div
                            style={{
                              maxWidth: 350,
                              height: 236,
                              borderRadius: 8,
                              backgroundColor: "#F9F9F9",
                              border: "2px solid #EAEAEA",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <img
                              style={{ width: 119, height: 119 }}
                              src={`./assets/images/page/suggestion/df-img.png`}
                              alt={"Witte-bangkok Suggestion"}
                            />
                          </div>
                        )}
                        <div style={{ paddingTop: 12 }}>
                          <Upload
                            {...optionInquiryImage}
                            accept="image/jpeg, image/png, image/jfif"
                            style={{ width: "100%" }}
                            maxCount={1}
                            showUploadList={{ showRemoveIcon: false }}
                          >
                            <Button
                              type="default"
                              style={{ width: "100%" }}
                              icon={
                                inquiryImage.loading ? (
                                  <LoadingOutlined />
                                ) : (
                                  <UploadOutlined />
                                )
                              }
                            >
                              อัพโหลดรูปภาพ
                            </Button>
                          </Upload>
                        </div>
                      </div>
                    </Col>

                    <Col span={24}>
                      <Divider style={{ marginTop: 12, marginBottom: 18 }} />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          type="primary"
                          size="middle"
                          onClick={() => {
                            form.submit();
                          }}
                        >
                          ส่งข้อมูล
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              :
              <div>
                <label>โปรดเข้าสู่ระบบก่อนสอบถาม หรือส่งคำเสนอเเนะ</label>
              </div>
            }
          </div>
        </div>
      </Content>
      <Footer />
    </>
  );
};

export default Suggestion;
