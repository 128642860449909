import "./fonts.css";

const themeAntd = {
  colorPrimary: "#F3BB02",
  fontSize: 16,
  fontFamily: "unset",

  rateStarColor: "white", // สีของดาวที่ยังไม่ได้เลือก
  rateStarSelectedColor: "#FF7F00", // สีของดาวที่ถูกเลือก
  rateStarSelectedBg: "transparent", // สีพื้นหลังของดาวที่ถูกเลือก
};

export default themeAntd;
