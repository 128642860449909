const initialState = {
  resultPreviewProductVideo: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "ADD_PREVIEW_PRODUCT_VIDEO":
      return {
        ...state,
        resultPreviewProductVideo: payload,
      };

    default:
      return state;
  }
};
