import { httpClient } from "../../../../utils/HttpClient";

export const getContactFetch = async () => {
  try {
    const result = await httpClient.get(`contact/get-page-contact`);

    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};
