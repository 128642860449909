import { server } from "../../../constants";
import { httpClient } from "../../../utils/HttpClient";

const getEBookAllFetch = async () => {
  try {
    const result = await httpClient.get(`ebook/get-ebook-all`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getEbookVideoUrl = async () => {
  try {
    const result = await httpClient.get(server.GET_EBOOK_VIDEO_URL);
    if (result.data.isSuccess) {
      return result.data.formData[0].videoUrl;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getEbookVideoUrl,
  getEBookAllFetch,
};
