import React, { useEffect, useState } from "react";
import "./css/index.css";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router";
import Card from "./components/Card";
import axios from "axios";
import { serverUrl } from "../../../../constants";
import LoadingPage from "../../../../common/components/loading/page";
const OurService = () => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (url) => {
    try {
      setIsLoading(true);
      // Make a GET request to the URL
      const response = await axios.get(
        `${serverUrl}/api/v1/service_us/get-service-us-all`
      );
      const data = response.data;

      if (data.isSuccess) {
        setServices(data.formData);
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching our services:", error);
      throw error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) return <LoadingPage />;
  return (
    <div className="services">
      <div className="container">
        <h2 style={{ color: "#FEE285", fontSize: 36, textAlign: "center" }}>
          บริการของเรา
        </h2>
        <Row
          gutter={[24, 24]}
          style={{
            justifyContent: "center",
            marginTop: 28,
          }}
        >
          {services.map((service, index) => (
            <Col
              key={service.id}
              span={24}
              md={12}
              lg={index < 3 ? 8 : 6} // Conditionally set lg based on index
            >
              <Card isSmall={index >= 3} service={service} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default OurService;
