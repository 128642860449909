/* eslint-disable react-hooks/exhaustive-deps */

import { Col, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { formatCodeUnit } from "../../../../common/mamager/IdManager";
import { getPaymentOrdersByIdFetch } from "../../API";
import LoadingPage from "../../../../common/components/loading/page";
import { useDimensions } from "../../../../common/mamager/ScreenManager";

const dateTime = "DD/MM/YYYY HH:mm";

export default function Transaction(props) {
  const columns = [
    {
      title: "No",
      // dataIndex: 'index',
      width: "2%",
      render: (text, record, index) => {
        return <div>{(pageOrderCurrent - 1) * pageSize + (index + 1)}.</div>;
      },
    },

    {
      title: "รายการ",
      dataIndex: "orderName",
      width: "10%",
    },
    {
      title: "สถานะ",
      dataIndex: "paymentStatusId",
      width: "8%",
      render: (text, record) => {
        if (record.paymentStatusId === 1) {
          return (
            <div style={{ color: "green" }}>{record.payment_status.name}</div>
          );
        } else if (record.paymentStatusId === 2) {
          return (
            <div style={{ color: "orange" }}>{record.payment_status.name}</div>
          );
        } else if (record.paymentStatusId === 3) {
          return (
            <div style={{ color: "red" }}>{record.payment_status.name}</div>
          );
        } else if (record.paymentStatusId === 4) {
          return <div>{record.payment_status.name}</div>;
        }
      },
    },
    {
      title: "เลขที่ชำระเงิน",
      dataIndex: "paymentOrderId",
      width: "4%",
      render: (text, record) => {
        return <div>{formatCodeUnit(record.paymentOrderId)}</div>;
      },
    },
    {
      title: "ราคาสุทธิ",
      dataIndex: "netPrice",
      width: "5%",
      render: (text, record) => {
        return (
          <>
            <label>
              {Intl.NumberFormat("en").format(Number(record.netPrice))} บาท
            </label>
          </>
        );
      },
    },
    {
      title: "วันที่ชำระ",
      dataIndex: "createdAt",
      width: "4%",
      render: (text, record) => {
        return (
          <div>
            {moment(record.createdAt).format(dateTime)} (
            {Number(moment(record.createdAt).format("YYYY")) + 543})
          </div>
        );
      },
    },
  ];

  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false); // true

  const [pageOrderCurrent, setPageOrderCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const { width } = useDimensions();
  const onOrderPagine = (n) => {
    setPageOrderCurrent(n.current);
  };

  const getPaymentOrdersById = async () => {
    const result = await getPaymentOrdersByIdFetch(props.user.uid);
    // console.log("getPaymentOrdersByIdFetch : ", result);
    setOrder(result);
  };

  const getBaseApi = async () => {
    setLoading(true);
    await getPaymentOrdersById();
    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          {!loading ? (
            <Table
              loading={loading}
              columns={columns}
              dataSource={order}
              pagination={{
                current: pageOrderCurrent,
                pageSize: pageSize,
                total: order?.length,
              }}
              style={{
                overflow: "auto",
                minWidth: "unset",
                maxWidth: `${
                  width < 400 ? "250px" : width < 600 ? "350px" : "100%"
                }`,
              }}
              onChange={(n) => onOrderPagine(n)}
            />
          ) : (
            <LoadingPage loading={loading} />
          )}
        </Col>
      </Row>
    </>
  );
}
