/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Menu, Row } from "antd";
import { useDimensions } from "../../common/mamager/ScreenManager";
import Transaction from "../payment/components/transaction";
import Header from "../../common/components/header";
import { Content } from "antd/lib/layout/layout";
import Footer from "../../common/components/footer";
import Profile from "./tabs/Profile";
import { getUserByUidFetch } from "../../common/components/header/API";
import { getCountryFetch, getProvincesFetch } from "../register/API";
import LoadingPage from "../../common/components/loading/page";
import PredictionRoomBoard from "../predictionRoom/PredictionRoomBoard";
import { getPredictionRoomByIdFetch } from "../predictionRoom/API";
import ClassroomBoard from "../classroom/components/ClassroomBoard";
import { getClassroomByIdFetch } from "../classroom/API";
import ExamRoomBoard from "../exam/components/examRoom/ExamRoomBoard";
import { getExamRoomAllFetch } from "../exam/API";

export default function Account(props) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tab = params.get("tab");
  const navigate = useNavigate();

  const { width } = useDimensions();

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    tab ? tab : "profile"
  );
  const [loading, setLoading] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(false);

  const [provincesData, setProvincesData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [predictionRoom, setPredictionRoom] = useState([]);
  const [classroom, setClassroom] = useState([]);
  const [examRoom, setExamRoom] = useState([]);

  const getPredictionRoomById = async () => {
    if (props?.user?.uid) {
      setLoading(true);
      const result = await getPredictionRoomByIdFetch({
        uid: props?.user?.uid,
      });
      // console.log("getPredictionRoomByIdFetch : ", result)
      setPredictionRoom(result);
      setLoading(false);
    }
  };

  const getClassroomById = async () => {
    if (props?.user?.uid) {
      setLoading(true);
      const result = await getClassroomByIdFetch(props?.user?.uid);
      // console.log("getClassroomByIdFetch : ", result)
      setClassroom(result);
      setLoading(false);
    }
  };

  const getExamRoomById = async () => {
    if (props?.user?.uid) {
      setLoading(true);
      const result = await getExamRoomAllFetch({ uid: props?.user?.uid });
      // console.log("getExamRoomAllFetch : ", result)
      setExamRoom(result);
      setLoading(false);
    }
  };

  const getUserByUid = async () => {
    const result = await getUserByUidFetch(props.user);
  };

  const handleReload = () => {
    setLoadingHeader(true);

    getUserByUid();
    // console.log("laoding headfer")
    setLoadingHeader(false);
  };

  const changeTab = (newTab) => {
    params.set("tab", newTab); // Set the new `tab` value
    navigate({
      pathname: location.pathname, // Keep the current pathname
      search: params.toString(), // Update the search params with the new tab value
    });
  };

  const componentsSwitch = (type) => {
    switch (type) {
      case "profile":
        return (
          <Profile
            provincesData={provincesData}
            countryData={countryData}
            handleReloadAccount={handleReload}
          />
        );
      case "myCourse":
        return (
          <>
            <ClassroomBoard classroom={classroom} user={props.user} />
            <ExamRoomBoard examRoom={examRoom} user={props.user} />
          </>
        );
      case "myPrediction":
        return (
          <PredictionRoomBoard
            predictionRoom={predictionRoom}
            user={props.user}
          />
        );
      case "paymentTransaction":
        return <Transaction user={props.user} handleReload={handleReload} />;

      default:
        return null;
    }
  };

  const getProvinces = async () => {
    let result = await getProvincesFetch();
    setProvincesData(result);
  };

  const getCountry = async () => {
    let result = await getCountryFetch();
    setCountryData(result);
  };

  const getBaseApi = async () => {
    setLoading(true);

    await getProvinces();
    await getCountry();

    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  const getData = async () => {
    setLoading(true);
    if (selectedMenuItem === "myCourse") {
      changeTab("myCourse");
      await getClassroomById();
      await getExamRoomById();
    } else if (selectedMenuItem === "myPrediction") {
      changeTab("myPrediction");
      await getPredictionRoomById();
    } else if (selectedMenuItem === "profile") {
      changeTab("profile");
    } else if (selectedMenuItem === "paymentTransaction") {
      changeTab("paymentTransaction");
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [selectedMenuItem]);

  // if (loading) return <LoadingPage loading={loading} />

  return (
    <>
      <Header
        firebase={props.firebase}
        authorized={props.authorized}
        user={props.user}
      />

      <article>
        <Content style={{ backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
          <div
            style={
              width > 1500
                ? {
                    backgroundColor: "white",
                    marginLeft: "5%",
                    marginRight: "5%",
                    minHeight: "100vh",
                  }
                : width > 912 && width <= 1500
                ? {
                    backgroundColor: "white",
                    marginLeft: "5%",
                    marginRight: "5%",
                    minHeight: "100vh",
                  }
                : {
                    backgroundColor: "white",
                    marginLeft: "0%",
                    marginRight: "0%",
                    minHeight: "100vh",
                  }
            }
          >
            <Row style={{ backgroundColor: "white", height: "100%" }}>
              <Col xs={4} sm={6} md={8} xl={4}>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[tab ? tab : "profile"]}
                  defaultOpenKeys={[tab ? tab : "profile"]}
                  style={{
                    height: "100%",
                    borderRight: 0,
                    padding: 0,
                    minHeight: "100vh",
                  }}
                  theme="dark"
                  selectedKeys={selectedMenuItem}
                  onClick={(e) => setSelectedMenuItem(e.key)}
                >
                  <Menu.Item key="profile" title="profile">
                    {width > 576 ? (
                      "ตั้งค่าบัญชี"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          icon="charm:person"
                          style={{ color: "#FF7F00", width: 24, height: 24 }}
                        />
                      </div>
                    )}
                  </Menu.Item>

                  <Menu.Item key="myCourse" title="myCourse">
                    {width > 576 ? (
                      "คอร์สของฉัน"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          icon="hugeicons:course"
                          style={{ color: "#FF7F00", width: 24, height: 24 }}
                        />
                      </div>
                    )}
                  </Menu.Item>

                  <Menu.Item key="myPrediction" title="myPrediction">
                    {width > 576 ? (
                      "โหรของฉัน"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          icon="fluent:predictions-20-regular"
                          style={{ color: "#FF7F00", width: 24, height: 24 }}
                        />
                      </div>
                    )}
                  </Menu.Item>

                  <Menu.Item
                    key="paymentTransaction"
                    title="PaymentTransaction"
                  >
                    {width > 576 ? (
                      "ประวัติการสั่งซื้อ"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          icon="icon-park-outline:transaction-order"
                          style={{ color: "#FF7F00", width: 24, height: 24 }}
                        />
                      </div>
                    )}
                  </Menu.Item>

                  <Menu.Item
                    key="logout"
                    title="logout"
                    style={{ color: "#FF7474" }}
                    onClick={() => {
                      props.firebase.auth().signOut();
                      navigate("/login");
                      window.scrollTo(0, 0);
                    }}
                  >
                    {width > 576 ? (
                      "ออกจากระบบ"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          icon="material-symbols:logout-rounded"
                          width="24"
                          height="24"
                          color="#FF7474"
                        />
                      </div>
                    )}
                  </Menu.Item>
                </Menu>
              </Col>

              <Col
                xs={20}
                sm={18}
                md={16}
                xl={20}
                style={{ borderLeft: "1px solid #ECECEC" }}
              >
                <div style={{ padding: width > 576 ? 24 : 12, minHeight: 500 }}>
                  {!loading ? (
                    componentsSwitch(selectedMenuItem)
                  ) : (
                    <LoadingPage loading={loading} />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </article>

      <Footer btnAutnActive={false} />
    </>
  );
}
