import { useEffect, useState } from "react";
import axios from "axios";
import LoadingPage from "../../common/components/loading/page";
import { useParams } from "react-router";
import Footer from "../../common/components/footer";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import Header from "../../common/components/header";
import { serverUrl } from "../../constants";
import parse from "html-react-parser";
import "./css/index.css";

const Blog = ({ firebase, user, authorized }) => {
  const [shotOfIdeaDetail, setShotOfIdeaDetail] = useState();

  //Comment set isLoading to false
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const getDetail = async () => {
      try {
        setIsLoading(true);

        // Fetch product detail
        const res = await axios.get(
          `${serverUrl}/api/v1/blog/get-blog-by-id/${id}`
        );
        const { data } = res;

        if (data.isSuccess) {
          setShotOfIdeaDetail(data.formData); // Set product detail
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching shot of idea detail:", error);
      }
    };
    getDetail();
  }, []);
  if (isLoading) return <LoadingPage loading={isLoading} />;
  return (
    <div className="prediction-detail">
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={shotOfIdeaDetail.title} />
      <section className="detail">
        <div className="main-bg">
          <div className="container" style={{ textAlign: "center" }}>
            <img
              src={`${serverUrl}/${shotOfIdeaDetail.imageDetailUrl}`}
              width={578}
              height={1106}
              alt="product"
              style={{
                maxWidth: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </div>
        </div>

        <div className="container">
          <div className="description">{parse(shotOfIdeaDetail?.detail)}</div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blog;
