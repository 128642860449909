/* eslint-disable react/style-prop-object */
/* eslint-disable no-array-constructor */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react"
import {
  Row,
  Col,
  Button,
  Divider,
  Select,
  Modal
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom"
import { useDimensions } from "./../../common/mamager/ScreenManager"
import Header from "./../../common/components/header"
import Footer from "./../../common/components/footer"
import LoadingPage from "./../../common/components/loading/page"
import parse from 'html-react-parser'
import BreadcrumbPage from "../../common/components/breadcrumbPage"
import FeedbackBoard from "../feedback/components/FeedbackBoard"
import firebase from "../../config/firebase-config"
import { useDispatch } from "react-redux"

import {
  getBirthdayUserByIdFetch,
  getFrtuneTellerQuestionsByIdFetch,
  getFrtuneTellerQuestionsMasterAllFetch,
  getKhunHorByIdFetch,
  getMandalaByIdFetch,
  getPredictionByIdFetch
} from "./API"
import {
  detectPredictionAlreadyPurchased,
  detectPredictionExpired,
  detectSignIn
} from "../../common/mamager/DetectManager"

import "./css/index.css"
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action"

const { Option } = Select

export default function PredictionOpen(props) {

  const navigate = useNavigate()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const predictionId = params.get("prediction-id")

  const [predictionResult1, setPredictionResult1] = useState(null)
  const [predictionResult2, setPredictionResult2] = useState(null)

  const [predicting, setPredicting] = useState(false)
  const [predicting2, setPredicting2] = useState(false)

  const [randomResult, setRandomResult] = useState(0)
  const [randomChanceOccurrenceResult, setRandomChanceOccurrenceResult] = useState(0)

  const [sizeRandom, setSizeRandom] = useState(0)
  const [amountCard, setAmountCard] = useState(0)

  const [delay1, setDelay1] = useState(false)
  const [delay2, setDelay2] = useState(false)
  const [delay3, setDelay3] = useState(false)
  const [delay4, setDelay4] = useState(false)

  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isMouseDown2, setIsMouseDown2] = useState(false)

  const [isOpenNumberRandom1, setIsOpenNumberRandom1] = useState(false)
  const [isOpenNumberRandom2, setIsOpenNumberRandom2] = useState(false)
  const [isOpenNumberRandom3, setIsOpenNumberRandom3] = useState(false)
  const [isOpenPredictionResult, setIsOpenPredictionResult] = useState(false)
  const [isOpenNumberRandomChanceOccurrence, setIsOpenNumberRandomChanceOccurrence] = useState(false)

  const [chanceOccurrence, setChanceOccurrence] = useState({
    title: null,
    imageUrl: null
  })

  const { width } = useDimensions()
  const dispatch = useDispatch();

  const [prediction, setPrediction] = useState(null)

  const [loading, setLoading] = useState(true)
  const [predictionStatus, setPredictionStatus] = useState("ALREADY_PURCHASED");

  const detectLogin = async () => {
    // detect login
    const isLogin = await detectSignIn()
    if (!isLogin) {
      navigate("/login")
    }
  }

  const detectAccessToPredictionOpen = async () => {
    if (props?.user?.uid) {
      const result = await detectPredictionAlreadyPurchased(
        props?.user?.uid,
        predictionId
      );
      if (result.isReady) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const getPredictionResult = async (key) => {
    if (predictionId) {
      if (Number(predictionId) === 1) { // ผลคำทำนายของ ขุนโหร-ONLINE
        setChanceOccurrence({
          title: "เจ้าเเม่ตะเคียนทอง",
          imageUrl: "./assets/images/prediction/chance-occurrence/chance-occurrence-type3.jpg"
        })
        const result = await getKhunHorByIdFetch(key)
        return result
      }
      if (Number(predictionId) === 2) { // ผลคำทำนายของ ดวงกาลจักร-ONLINE
        setChanceOccurrence({
          title: "กาลจักร",
          imageUrl: "./assets/images/prediction/chance-occurrence/chance-occurrence-type3.jpg"
        })
        const result = await getMandalaByIdFetch(key)
        return result
      }
      if (Number(predictionId) === 3) { // ผลคำทำนายของ ขุนโหร-ONLINE
        setChanceOccurrence({
          title: "เซียนแห่งเต๋า",
          imageUrl: "./assets/images/prediction/chance-occurrence/chance-occurrence-type3.jpg"
        })
        const result = await getKhunHorByIdFetch(key)

        return result
      }
    }
    return null
  }

  const genRandom = (sizeRandom) => {
    return Math.floor(Math.random() * sizeRandom) + 1;
  }

  const calculateAge = (birthDate) => {
    var today = new Date();
    var birthDateArr = birthDate.split('/');
    var birthYear = parseInt(birthDateArr[2]);
    var birthMonth = parseInt(birthDateArr[1]) - 1; // Month starts from 0
    var birthDay = parseInt(birthDateArr[0]);

    var age = today.getFullYear() - birthYear;
    var m = today.getMonth() - birthMonth;

    if (m < 0 || (m === 0 && today.getDate() < birthDay)) {
      age--;
    }

    return age;
  }

  const handlePrediction = async () => {
    let random = genRandom(sizeRandom)

    let randomArr = new Array()
    if (amountCard === 1) {
      randomArr = [String(random)]
    } else if (amountCard === 2) {
      if (random <= 9) {
        randomArr = shuffleArray(["0", String(random)])
      } else if (random <= 99) {
        // 
        randomArr = shuffleArray([String(random)[0], String(random)[1]])
      }
    } else if (amountCard === 3) {
      if (random <= 9) {
        randomArr = shuffleArray(["0", "0", String(random)])
      } else if (random <= 99) {
        // 
        randomArr = shuffleArray(["0", String(random)[0], String(random)[1]])
      } else {
        // 
        randomArr = shuffleArray([String(random)[0], String(random)[1], String(random)[2]])
      }
    }
    setRandomResult(randomArr)

    let resultUser = await getBirthdayUserByIdFetch(props?.user?.uid)
    let birthday = null
    let age = null
    if (resultUser) {
      birthday = `${resultUser.dateOfBirth}/${resultUser.monthOfBirth}/${resultUser.yearOfBirth - 543} ${resultUser.timeOfBirth}`
      age = calculateAge(birthday)
    }
    let calcurate1 = random + age
    let calcurate2 = random - age

    if (calcurate1 > (sizeRandom + 1)) {
      calcurate1 = calcurate1 - (sizeRandom + 1)
    }

    if (calcurate2 < 0) {
      calcurate2 = calcurate2 + (sizeRandom + 1)
    }

    console.log("calcurate1 --- : ", calcurate1)
    let result1 = await getPredictionResult(calcurate1)
    setPredictionResult1(result1)

    console.log("calcurate2 --- : ", calcurate2)
    let result2 = await getPredictionResult(calcurate2)
    setPredictionResult2(result2)

    setPredicting(true)
    if (amountCard >= 1) {
      setDelay1(true)
      setTimeout(function () {
        setDelay1(false)
        setIsOpenNumberRandom1(true)

        // ------------
        if (amountCard >= 2) {
          setDelay2(true)
          setTimeout(function () {
            setDelay2(false)
            setIsOpenNumberRandom2(true)

            if (amountCard === 2) {
              setIsOpenPredictionResult(true) // ***
              // timeOurOccurrence()
              setPredicting(false)
            }

            // ------------
            if (amountCard >= 3) {
              setDelay3(true)
              setTimeout(function () {
                setDelay3(false)
                setIsOpenNumberRandom3(true)

                setIsOpenPredictionResult(true) // ***
                // timeOurOccurrence()
                setPredicting(false)
              }, 3000)
            }
            // ------------

          }, 3000)
        }
        // ------------

      }, 3000)
    }
  }

  const timeOurOccurrence = () => {
    randomChanceOccurrence()
    setDelay4(true)
    setTimeout(function () {
      setDelay4(false)
      setIsOpenNumberRandomChanceOccurrence(true)
    }, 5000)
  }

  const randomChanceOccurrence = () => {
    let random = genRandom(3)
    setRandomChanceOccurrenceResult(random)
  }

  const getPredictionById = async () => {
    let result = await getPredictionByIdFetch(predictionId)
    // console.log("result: ", result)
    setPrediction(result ?? null)
  }

  const [frtuneTellerQuestionsMaster, setFrtuneTellerQuestionsMaster] = useState(null)
  const [frtuneTellerQuestions, setFrtuneTellerQuestions] = useState(null)

  const setFrtuneTellerQuestionsMasterSelectedRef = useRef(null)
  const setFrtuneTellerQuestionsSelectedRef = useRef(null)

  const [frtuneTellerQuestionsMasterSelected, setFrtuneTellerQuestionsMasterSelected] = useState(null)
  const [frtuneTellerQuestionsSelected, setFrtuneTellerQuestionsSelected] = useState(null)

  const getFrtuneTellerQuestionsMasterAll = async () => {
    let result = await getFrtuneTellerQuestionsMasterAllFetch()
    // console.log("getFrtuneTellerQuestionsMasterAllFetch: ", result)
    setFrtuneTellerQuestionsMaster(result ?? null)
  }

  const getFrtuneTellerQuestionsById = async (id) => {
    let result = await getFrtuneTellerQuestionsByIdFetch(id)
    // console.log("getFrtuneTellerQuestionsByIdFetch: ", result)
    setFrtuneTellerQuestions(result ?? null)
  }

  const getBaseApi = async () => {
    setLoading(true)

    await detectLogin()
    await detectAccessToPredictionOpen()
    await getPredictionById()
    await getFrtuneTellerQuestionsMasterAll()
    await getFrtuneTellerQuestionsById(null)

    await getPredictionResult(3)

    const responseDetectPredictionPurchased =
      await detectPredictionAlreadyPurchased(
        firebase.auth().currentUser?.uid,
        predictionId
      );

    const responseDetectPredictionExpired = await detectPredictionExpired(
      firebase.auth().currentUser?.uid,
      predictionId
    );

    if (responseDetectPredictionExpired === "EXPIRED") {
      setPredictionStatus("EXPIRED");
    } else if (
      responseDetectPredictionPurchased === "AWAITING_PAYMENT_APPROVAL"
    ) {
      setPredictionStatus("AWAITING_PAYMENT_APPROVAL");
    } else if (responseDetectPredictionPurchased === "ALREADY_PURCHASED") {
      setPredictionStatus("ALREADY_PURCHASED");
    } else {
      setPredictionStatus("NOT_PURCHASED");
    }

    setLoading(false)
  }

  const getDataByPredictionType = () => {
    if (predictionId) {
      if (Number(predictionId) === 2) { // ผลคำทำนายของ ดวงกาลจักร-ONLINE
        setSizeRandom(359)
        setAmountCard(3)
      }
      if (Number(predictionId) === 1) { // ผลคำทำนายของ ขุนโหร-ONLINE
        setSizeRandom(99)
        setAmountCard(2)
      }
    }
  }

  const genChanceOccurrence = () => {
    return (
      <div style={{}}>
        <Row gutter={[0, 12]}>
          <Col span={24}>
            {/* <label style={{ fontSize: 20, fontWeight: 600 }}>สอบถามจาก  {chanceOccurrence.title} ต่อไปไหม ?</label> */}
            <div style={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              {/* {predictionId !== "3" ?
                                <label style={{ fontSize: 30, fontWeight: 600 }}>เสี่ยงทายกับ {chanceOccurrence.title} ให้โชค</label>
                                :
                                <label style={{ fontSize: 30, fontWeight: 600 }}>เสี่ยงทายกับ {chanceOccurrence.title}</label>
                            } */}
              <label style={{ fontSize: 30, fontWeight: 600 }}>เสี่ยงทายกับเซียนแห่งเต๋า</label>

              <label style={{ fontSize: 20 }}>กดปุ่มเสี่ยงทาย ค้างไว้ ปล่อยมือเมื่อพร้อม</label>
            </div>
          </Col>
          <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Button
              type="primary"
              loading={delay4}
              style={{ width: 170 }}
              // disabled={frtuneTellerQuestionsSelected ? false : true}
              onClick={() => {
                // timeOurOccurrence()
              }}
              onMouseDown={() => {
                setIsMouseDown2(true)
              }}
              onMouseUp={() => {
                if (!predicting) {
                  setDefault2()
                  timeOurOccurrence()
                  setIsMouseDown2(false)
                }
              }}
            >
              {delay4 ? "กำลังทำนาย" :
                (isMouseDown2 ? "ปล่อยปุ่ม" : "กดปุ่มเสี่ยงทาย")
              }
            </Button>
          </Col>

          {isOpenNumberRandomChanceOccurrence ?
            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {/* <label style={{ fontSize: 20, fontWeight: 600 }}>{chanceOccurrence.title} บอกให้ท่านทราบว่า ...</label> */}
              <label style={{ fontSize: 20, fontWeight: 600 }}>เซียนแห่งเต๋าบอกให้ท่านทราบว่า ...</label>
            </Col>
            : []
          }


          <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {!delay4 ?
              // <>a</>
              <img
                src={chanceOccurrence.imageUrl}
                style={{ height: 300, borderRadius: 8 }}
                alt={"Witte-bangkok Prediction open"}
              />
              :
              <>
                <div class="card-chance-occurrence rotate-chance-occurrence">
                  <img
                    src={chanceOccurrence.imageUrl}
                    style={{ height: 300, borderRadius: 8 }}
                    alt={"Witte-bangkok Chance occurrence"}
                  />
                </div>
              </>
            }
          </Col>

          {isOpenNumberRandomChanceOccurrence ?
            <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div className="predict-result-board">
                <label className="predict-result-text">
                  {randomChanceOccurrenceResult === 1 ?
                    <div style={{ width: 300, borderRadius: 8, padding: 12, textAlign: "center", backgroundColor: "#EEEEEE", display: "grid", alignItems: "center", justifyContent: "center" }}>
                      <label style={{ fontSize: 30 }}>ผลการเสี่ยงทาย</label>
                      {/* <label style={{ fontSize: 26 }}>มีโอกาสเป็นไปได้</label> */}
                      <img
                        src={`./assets/images/prediction/chance-occurrence/result-maybe.jpg`}
                        style={{ height: 300, borderRadius: 8 }}
                        alt={"Witte-bangkok Prediction open"}
                      />
                    </div>
                    : []
                  }

                  {randomChanceOccurrenceResult === 2 ?
                    <div style={{ width: 300, borderRadius: 8, padding: 12, textAlign: "center", backgroundColor: "#EEEEEE", display: "grid", alignItems: "center", justifyContent: "center" }}>
                      <label style={{ fontSize: 30 }}>ผลการเสี่ยงทาย</label>
                      {/* <label style={{ fontSize: 26 }}>ไม่ชัดเจน โปรดลองใหม่</label> */}
                      <img
                        src={`./assets/images/prediction/chance-occurrence/result-true.jpg`}
                        style={{ height: 300, borderRadius: 8 }}
                        alt={"Witte-bangkok Prediction open"}
                      />
                    </div>
                    : []
                  }

                  {randomChanceOccurrenceResult === 3 ?
                    <div style={{ width: 300, borderRadius: 8, padding: 12, textAlign: "center", backgroundColor: "#EEEEEE", display: "grid", alignItems: "center", justifyContent: "center" }}>
                      <label style={{ fontSize: 30 }}>ผลการเสี่ยงทาย</label>
                      {/* <label style={{ fontSize: 26 }}>มีโอกาสเป็นไปไม่ได้</label> */}
                      <img
                        src={`./assets/images/prediction/chance-occurrence/result-false.jpg`}
                        style={{ height: 300, borderRadius: 8 }}
                        alt={"Witte-bangkok Prediction open"}
                      />
                    </div>
                    : []
                  }
                </label>
              </div>
            </Col>
            : []
          }
        </Row>
      </div>
    )
  }

  const setDefault = () => {
    setIsOpenNumberRandom1(false)
    setIsOpenNumberRandom2(false)
    setIsOpenNumberRandom3(false)
    setIsOpenPredictionResult(false)
    setIsOpenNumberRandomChanceOccurrence(false)
  }

  const setDefault2 = () => {
    setIsOpenNumberRandomChanceOccurrence(false)
  }

  function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // เลือกสุ่มตำแหน่งในอาร์เรย์
      [arr[i], arr[j]] = [arr[j], arr[i]]; // สลับสมาชิกที่ตำแหน่ง i และ j
    }
    return arr;
  }

  useEffect(() => {

    const originalArray = [1, 2];
    const shuffledArray = shuffleArray(originalArray);
    // console.log(shuffledArray);

    getDataByPredictionType()
    getBaseApi()
  }, [])

  const genGridCard = () => {
    let grid = null
    if (amountCard >= 3) {
      grid = 8
    } else if (amountCard >= 2) {
      grid = 12
    } else if (amountCard >= 1) {
      grid = 24
    }

    return grid
  }

  return (
    <>
      <Header
        firebase={props.firebase}
        authorized={props.authorized}
        user={props.user}
      />
      {!loading ? <BreadcrumbPage pageCurrentName={prediction?.title} /> : []}

      <article>
        {!loading ?
          <div
            style={{
              display: width > 590 ? "flex" : "grid",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingLeft: width > 925 ? '10%' : 12,
              paddingRight: width > 925 ? '10%' : 12,
              paddingTop: 24
            }}
          >
            {predictionStatus !== "AWAITING_PAYMENT_APPROVAL" &&
              predictionStatus !== "EXPIRED" &&
              predictionStatus !== "NOT_PURCHASED" && (
                <Row gutter={[24, 24]}>
                  <Col xs={24} md={24} xl={24}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Row gutter={[24, 24]}>
                        {amountCard >= 1 ?
                          <Col xs={24} md={genGridCard()} xl={genGridCard()}>
                            {!delay1 ?
                              <>
                                {isOpenNumberRandom1 ?
                                  <div style={{ height: 234, width: 156, backgroundImage: `url(./assets/images/prediction/card-after.jpg`, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <label style={{ fontSize: 99, fontWeight: 600, color: "white" }}>{randomResult[0]}</label>
                                  </div>
                                  :
                                  <img
                                    src={predictionId === "2" ? "./assets/images/prediction/card-before1.jpg" : "./assets/images/prediction/card-before2.jpg"}
                                    style={{ height: 234 }} 
                                    alt={"Witte-bangkok Prediction room"}
                                  />
                                }
                              </>
                              :
                              <div class="card rotate">
                                <img
                                  src={predictionId === "2" ? "./assets/images/prediction/card-before1.jpg" : "./assets/images/prediction/card-before2.jpg"}
                                  style={{ height: 234 }} 
                                  alt={"Witte-bangkok Prediction room"}
                                />
                              </div>
                            }
                          </Col>
                          : []
                        }

                        {amountCard >= 2 ?
                          <Col xs={24} md={genGridCard()} xl={genGridCard()}>
                            {!delay2 ?
                              <>
                                {isOpenNumberRandom2 ?
                                  <div style={{ height: 234, width: 156, backgroundImage: `url(./assets/images/prediction/card-after.jpg`, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <label style={{ fontSize: 99, fontWeight: 600, color: "white" }}>{randomResult[1]}</label>
                                  </div>
                                  :
                                  <img
                                    src={predictionId === "2" ? "./assets/images/prediction/card-before1.jpg" : "./assets/images/prediction/card-before2.jpg"}
                                    style={{ height: 234 }} 
                                    alt={"Witte-bangkok Prediction room"}
                                  />
                                }
                              </>
                              :
                              <div class="card rotate">
                                <img
                                  src={predictionId === "2" ? "./assets/images/prediction/card-before1.jpg" : "./assets/images/prediction/card-before2.jpg"}
                                  style={{ height: 234 }} 
                                  alt={"Witte-bangkok Prediction room"}
                                />
                              </div>
                            }
                          </Col>
                          : []
                        }

                        {amountCard >= 3 ?
                          <Col xs={24} md={genGridCard()} xl={genGridCard()}>
                            {!delay3 ?
                              <>
                                {isOpenNumberRandom3 ?
                                  <div style={{ height: 234, width: 156, backgroundImage: `url(./assets/images/prediction/card-after.jpg`, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <label style={{ fontSize: 99, fontWeight: 600, color: "white" }}>{randomResult[2]}</label>
                                  </div>
                                  :
                                  <img
                                    src={predictionId === "2" ? "./assets/images/prediction/card-before1.jpg" : "./assets/images/prediction/card-before2.jpg"}
                                    style={{ height: 234 }} 
                                    alt={"Witte-bangkok Prediction room"}
                                  />
                                }
                              </>
                              :
                              <div class="card rotate">
                                <img
                                  src={predictionId === "2" ? "./assets/images/prediction/card-before1.jpg" : "./assets/images/prediction/card-before2.jpg"}
                                  style={{ height: 234 }} 
                                  alt={"Witte-bangkok Prediction room"}
                                />
                              </div>
                            }
                          </Col>
                          : []
                        }
                      </Row>
                    </div>
                  </Col>

                  <Col span={24}>
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
                      <Select
                        showSearch
                        style={{ width: 400 }}
                        placeholder="เลือกคำถามที่อยากสอบถาม"
                        optionFilterProp="children"
                        allowClear
                        onChange={(e) => {
                          setFrtuneTellerQuestionsSelectedRef.current = e
                          setFrtuneTellerQuestionsSelected(e)
                        }}
                      >
                        {frtuneTellerQuestions?.map((val, index) =>
                          <Option key={index} value={val.fortuneTellerQuestionsId}>{val.fortune_teller_questions_master?.name + " : " + val?.name}</Option>
                        )}
                      </Select>
                    </div>
                  </Col>

                  {predictionId !== '3' ?
                    <Col span={24}>
                      <div style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
                        <Button
                          type="primary"
                          size="large"
                          loading={predicting}
                          disabled={frtuneTellerQuestionsSelected ? false : true}
                          onMouseDown={() => {
                            setIsMouseDown(true)
                          }}
                          onMouseUp={() => {
                            if (!predicting) {
                              setDefault()
                              handlePrediction()
                              setIsMouseDown(false)
                            }
                          }}
                        >
                          <label style={{ cursor: "pointer" }}>
                            {predicting ?
                              "กำลังทำนาย"
                              :
                              (isMouseDown ?
                                "ปล่อยปุ่ม"
                                :
                                !isOpenPredictionResult ? "คลิกทำนาย" : "ลองอีกครั้ง (ไม่ควรเกิน 3 ครั้ง/วัน)"
                              )
                            }
                          </label>
                        </Button>
                      </div>
                    </Col>
                    : []
                  }

                  {isOpenPredictionResult ?
                    <>
                      <Col xs={24} md={24} xl={24}>
                        <div style={{ backgroundColor: "#EEEEEE", padding: 24, borderRadius: 12 }}>
                          <div className="predict-result-board">
                            <label className="predict-result-text">
                              <label style={{ fontWeight: 600 }}>
                                {/* คำทำนายที่ 1 */}
                                ผลคำทำนาย
                              </label>
                            </label>
                          </div>

                          <div className="predict-result-board">
                            <label className="predict-result-text">{predictionResult1?.detail ? parse(predictionResult1?.detail) : []}</label>
                          </div>
                        </div>
                      </Col>

                      <Col span={24}>
                        <Divider style={{ marginTop: 11, marginBottom: 11 }} />
                      </Col>
                    </>
                    : []
                  }

                  {Number(predictionId) === 3 ?
                    <Col span={24} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {genChanceOccurrence()}
                    </Col>
                    : []
                  }

                  <Col xs={24} md={24} xl={24} style={{ paddingBottom: 24 }}>
                    <label>{prediction?.detail ? parse(prediction?.detail) : []}</label>
                  </Col>

                  <Col xs={24} md={24} xl={24} style={{ paddingBottom: 24 }}>
                    <FeedbackBoard feedbackCategoryId={1} />
                  </Col>
                </Row>
              )
            }

            <Modal
              title={
                <div>
                  <label style={{ fontWeight: 700, fontSize: 20 }}>
                    {predictionStatus === "AWAITING_PAYMENT_APPROVAL" &&
                      "โหรอยู่ระหว่างตรวจสอบชำระเงิน"}
                    {predictionStatus === "EXPIRED" && "โหรนี้หมดอายุเเล้ว"}
                    {predictionStatus === "NOT_PURCHASED" &&
                      "โหรนี้ยังไม่ได้ชำระเงิน"}
                  </label>
                  <Divider />
                </div>
              }
              maskStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.9)", // Dark transparent background for modal overlay
              }}
              centered
              open={
                predictionStatus === "AWAITING_PAYMENT_APPROVAL" ||
                predictionStatus === "EXPIRED" ||
                predictionStatus === "NOT_PURCHASED"
              }
              footer={null}
              closable={false}
              width={700}
            >
              <Row gutter={[16, 12]}>
                <Col span={24} style={{ fontSize: 20 }}>
                  {predictionStatus === "AWAITING_PAYMENT_APPROVAL" &&
                    "โปรดกลับเข้ามาใหม่อีกครั้งภายหลัง"}
                  {predictionStatus === "EXPIRED" &&
                    "หากต้องการใช้งานอีกครั้ง กรุณาต่ออายุการใช้งาน"}
                  {predictionStatus === "NOT_PURCHASED" &&
                    "โปรดชำระเงินก่อนเข้าเรียน!"}
                </Col>
                <Col span={24}>
                  <Button
                    type="default"
                    style={{ color: "#F3BB02", borderColor: "#F3BB02" }}
                    onClick={() => navigate("/")}
                  >
                    ไปหน้าหลัก
                  </Button>
                  {predictionStatus === "EXPIRED" && (
                    <Button
                      type="primary"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        navigate("/payment")
                        const _prediction = [prediction]
                        dispatch(setBasketOrderStateToSuccess(_prediction))
                      }}
                    >
                      ต่ออายุการใช้งาน
                    </Button>
                  )}
                  {predictionStatus === "NOT_PURCHASED" && (
                    <Button
                      type="primary"
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        navigate("/payment")
                        const _prediction = [prediction]
                        dispatch(setBasketOrderStateToSuccess(_prediction))
                      }}
                    >
                      สังซื้อเลย
                    </Button>
                  )}
                </Col>
              </Row>
            </Modal>
          </div>
          :
          <LoadingPage loading={loading} />
        }
      </article>

      <Footer />
    </>
  )
}