/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Space, List, Modal } from "antd";
import moment from "moment";
import { useDimensions } from "../../../../common/mamager/ScreenManager";
import { serverUrl } from "../../../../constants";
import { useEffect, useState, useRef } from "react";
import { getExamInspectLogByIdFetch } from "../../API";

const dateTimeFormat = "DD/MM/YYYY HH:mm";

export default function ExamRoomItem(props) {
  const navigate = useNavigate();
  const { width } = useDimensions();

  const [loading, setLoading] = useState(false);

  const [examInspectLog, setExamInspectLog] = useState();

  const [modal, setModal] = useState({
    isShow: false,
  });

  const data = props.item;

  async function handleDownloadClick(url, username) {
    // const url = 'your_pdf_file.pdf'; // URL ของไฟล์ PDF

    try {
      const response = await fetch(url); // ดาวน์โหลดไฟล์จาก URL
      const blob = await response.blob(); // สร้าง Blob จากข้อมูลที่ดาวน์โหลด

      // สร้าง URL สำหรับ Blob
      const blobURL = URL.createObjectURL(blob);

      // สร้างลิงก์สำหรับการดาวน์โหลดไฟล์
      const a = document.createElement("a");
      a.href = blobURL;
      a.download = `Certificate-${username}.pdf`; // กำหนดชื่อไฟล์ที่ต้องการให้ดาวน์โหลด

      // คลิกที่ลิงก์เพื่อดาวน์โหลดไฟล์
      document.body.appendChild(a);
      a.click();

      // ลบลิงก์และ URL ที่สร้างขึ้น
      window.URL.revokeObjectURL(blobURL);
      document.body.removeChild(a);
    } catch (error) {
      // console.error('Error downloading PDF:', error);
    }
  }

  const getExamInspectLogById = async () => {
    setLoading(true);

    if (props?.user?.uid) {
      const result = await getExamInspectLogByIdFetch(data?.examRoomId);
      // console.log("getExamInspectLogByIdFetch : ", result)
      setExamInspectLog(result);
    }

    setLoading(false);
  };

  const getBaseApi = async () => {
    await getExamInspectLogById();
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  // console.log("data : ", data)

  return (
    <>
      <Col
        span={24}
        style={{
          boxShadow: "0px 4px 8px #00000040",
          borderRadius: 6,
          marginBottom: 24,
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: 12,
            borderRadius: 6,
            display: "flex",
            flexWrap: "wrap", // This ensures the layout is responsive
            alignItems: "flex-start", // Align items to the top
            gap: 12,
          }}
        >
          {/* Picture on the Left */}
          <div style={{}}>
            <img
              src={`${serverUrl}/${data?.course?.imgCoverUrl}`}
              alt={"Witte-bangkok Course cover"}
              style={{
                width: width < 440 ? "100%" : 142,
                objectFit: width < 440 ? "cover" : "contain",
                height: width < 440 ? "auto" : 102,
                borderRadius: "8px",
              }}
            />
          </div>

          {/* Content in the Center */}
          <div
            style={{
              flex: "1", // Take up remaining space
              display: "flex",
              flexDirection: "column",
              gap: "6px",
              minWidth: "200px", // Ensure the content has a minimum width
            }}
          >
            <div style={{ fontWeight: "bolder" }}>
              <label>{data?.course?.title ?? "-"}</label>
            </div>

            <div>
              <label style={{ color: "black" }}>
                วันที่ซื้อสิทธิการสอบ{" "}
                {moment(data?.createdAt).format(dateTimeFormat)} (
                {Number(moment(data?.createdAt).format("YYYY")) + 543})
              </label>
            </div>
            <div>
              <label style={{ color: "black" }}>
                สถานะการสอบ {data?.exam_room_status?.name}
              </label>
            </div>
            <div>
              <label style={{ color: "black" }}>
                จำนวนครั้งที่สอบ {examInspectLog?.length ?? 0} ครั้ง{" "}
                <span style={{ color: "red" }}>(สอบได้สูงสุด 3 ครั้ง)</span>
              </label>
            </div>
          </div>

          {/* Button on the Right */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end", // Align buttons to the right
              alignItems: "flex-start", // Align to top
            }}
          >
            <Space size={10}>
              {[1, 2, 3, 4].includes(
                data?.exam_room_status?.examRoomStatusId
              ) && (
                <Button
                  type="primary"
                  disabled={
                    [2, 3].includes(data?.exam_room_status?.examRoomStatusId)
                      ? false
                      : true
                  }
                  onClick={() => {
                    navigate("/exam?examRoomId=" + data?.examRoomId);
                  }}
                >
                  <label style={{ cursor: "pointer" }}>เข้าสอบ</label>
                </Button>
              )}
            </Space>
          </div>
        </div>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">ประวัติการสอบ</label>
          </strong>
        }
        visible={modal.isShow}
        onCancel={() => {
          setModal({ isShow: false });
        }}
        footer={[]}
      >
        <Row gutter={[24, 0]}>
          <Col
            span={8}
            style={{
              backgroundColor: "#D9D9D9",
              padding: 6,
              textAlign: "center",
            }}
          >
            <label style={{ fontWeight: 600 }}>วันที่ประกาศผลสอบ</label>
          </Col>
          <Col
            span={8}
            style={{
              backgroundColor: "#D9D9D9",
              padding: 6,
              textAlign: "center",
            }}
          >
            <label style={{ fontWeight: 600 }}>ผลสอบ</label>
          </Col>
          <Col
            span={8}
            style={{
              backgroundColor: "#D9D9D9",
              padding: 6,
              textAlign: "center",
            }}
          >
            <label style={{ fontWeight: 600 }}>คะเเนนรวม</label>
          </Col>

          {examInspectLog?.map((item) => {
            return (
              <>
                <Col
                  span={8}
                  style={{
                    padding: 6,
                    textAlign: "center",
                    borderBottom: "1px solid #DDDDDD",
                  }}
                >
                  <label style={{}}>
                    {moment(data?.createdAt).format(dateTimeFormat)} (
                    {Number(moment(item.createdAt).format("YYYY")) + 543})
                  </label>
                </Col>
                <Col
                  span={8}
                  style={{
                    padding: 6,
                    textAlign: "center",
                    borderBottom: "1px solid #DDDDDD",
                  }}
                >
                  <label style={{}}>
                    {item?.exam_room_status?.name ?? "-"}
                  </label>
                </Col>
                <Col
                  span={8}
                  style={{
                    padding: 6,
                    textAlign: "center",
                    borderBottom: "1px solid #DDDDDD",
                  }}
                >
                  <label style={{}}>
                    {item.score} / {item.fullScore}
                  </label>
                </Col>
              </>
            );
          })}
        </Row>
      </Modal>
    </>
  );
}
