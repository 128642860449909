/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import "../banner/css/index.css"

import { serverUrl } from "../../../constants"
import { useEffect, useState } from "react"
import { useDimensions } from "../../mamager/ScreenManager"
import ReactPlayer from 'react-player'

export default function CarouselList(props) {

    const { width } = useDimensions()
    const [imgWidth, setImgWidth] = useState(width * (width > 1500 ? 0.320 : ((width >= 521 && width <= 1500) ? 0.479 : 0.913)))

    useEffect(() => {
        setImgWidth(width * (width > 1500 ? 0.320 : ((width >= 521 && width <= 1500) ? 0.479 : 0.913)))
    }, [width])

    return (
        <div className="banner">
            {props.bannerData?.length > 0 ?
                <Swiper
                    modules={[Pagination, Navigation, Mousewheel, Keyboard, Autoplay]}
                    slidesPerView={props.bannerData?.length <= 1 ? 1 : (width > 1500 ? 3 : ((width >= 760 && width <= 1500) ? 2 : 1))}
                    centeredSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    keyboard={{ enabled: true }}
                    pagination={{
                        clickable: true
                    }}
                    navigation={true}
                    loop={true}
                    className="mySwiper"
                >
                    {props.bannerData.map((banners, index) =>
                        <SwiperSlide key={index}>
                            <center>
                                {banners?.displayTypeId === 1 ?
                                    <a href={(banners?.linkUrl === "" || banners?.linkUrl === "-") ? "_blank" : banners?.linkUrl} target="_blank">
                                        <img
                                            src={`${serverUrl}/${banners.imageUrl}`}
                                            alt={"Witte-bangkok Banner"}
                                            style={{
                                                borderRadius: 15,
                                                width: (width > 1580 ? 500 : ((width > 760 && width < 1580) ? "95%" : "92.2%")),
                                                height: 250,
                                                padding: 0,
                                            }}
                                        />
                                    </a>
                                    :
                                    <ReactPlayer
                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                        onContextMenu={e => e.preventDefault()}
                                        url={`${serverUrl}/${banners.videoUrl}`}
                                        controls
                                        width={(width > 1580 ? 500 : ((width > 760 && width < 1580) ? "95%" : "92.2%"))}
                                        height={250}
                                    />
                                }
                            </center>
                        </SwiperSlide>
                    )}
                </Swiper>
                : []
            }
        </div>
    )
}