import { useEffect, useState } from 'react'
import CarouselList from '../../../../common/components/banner/CarouselList'
import { getBannerAllFetch } from '../../API'

export default function Banner() {

    const [loading, setLoading] = useState(false)

    const [data, setData] = useState(null)

    const getBannerAll = async () => {
        let result = await getBannerAllFetch()
        console.log("getBannerAllFetch --- : ", result)
        
        setData(result)
    }

    useEffect(() => {
        getBannerAll()
    }, [])

    return (
        <div
            style={{
                paddingTop: 24,
                paddingBottom: 24,
                backgroundColor: "#ABABAB"
            }}
        >
            {!loading ?
                <CarouselList bannerData={data} />
                : []
            }
        </div>
    )
}
