import { httpClient } from "../../../utils/HttpClient"

const getCourseAllFetch = async () => {
  try {
    const result = await httpClient.get(`course/get-course-all`)
    if (result.data.isSuccess) {
      return result.data.formData
    } else {
      return null
    }
  } catch (err) {
    // status 404
    return null
  }
}

const getDetectCourseAwaitingPaymentVerificationFetch = async (value) => {
  try {
  const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
  const queryParams = new URLSearchParams(filteredValue).toString()
      const result = await httpClient.get(`course/get-detect-course-awaiting-payment-verification?${queryParams}`)
      if (result.data.isSuccess) {
          return result.data.formData
      } else {
          return []
      }
  } catch (err) { // status 404
      return null
  }
}

const getDetectCourseAlreadyPurchasedFetch = async (value) => {
  try {
  const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
  const queryParams = new URLSearchParams(filteredValue).toString()
      const result = await httpClient.get(`course/get-detect-course-already-purchased?${queryParams}`)
      if (result.data.isSuccess) {
          return result.data.formData
      } else {
          return []
      }
  } catch (err) { // status 404
      return null
  }
}

const getDetectCourseExpiredByIdFetch = async (value) => {
  try {
  const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
  const queryParams = new URLSearchParams(filteredValue).toString()
      const result = await httpClient.get(`course/get-detect-course-expired-by-id?${queryParams}`)
      if (result.data.isSuccess) {
          return result.data.formData
      } else {
          return null
      }
  } catch (err) {}
}

export {
  // get
  getCourseAllFetch,
  getDetectCourseAwaitingPaymentVerificationFetch,
  getDetectCourseAlreadyPurchasedFetch,
  getDetectCourseExpiredByIdFetch
}
