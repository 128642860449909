import { httpClient } from "../../../utils/HttpClient";

const getClassroomByIdFetch = async (uid) => {
  try {
    const result = await httpClient.get(
      `classroom/get-classroom-by-id?uid=${uid}`
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

export {
  // get
  getClassroomByIdFetch,

  // insert

  // update

  // delete
};
