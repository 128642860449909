/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import "../../modules/prediction/css/prediction.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../../common/mamager/ScreenManager";
import Header from "../../common/components/header";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import Footer from "../../common/components/footer";
import LoadingPage from "../../common/components/loading/page";
import { serverUrl } from "../../constants";
import { getPredictionAllFetch } from "./API";
import { setBasketOrderStateToSuccess } from "../../actions/basketOrder.action";
import { useDispatch, useSelector } from "react-redux";
// import { getUsageByIdFetch } from "../erp/manage/usage/API";
import CustomVideo from "../../common/components/videoJS";
import ProductList from "./components/ProductList";
import { detectPredictionAlreadyPurchased } from "../../common/mamager/DetectManager";

export default function Prediction({ authorized, firebase, user }) {
  const { width } = useDimensions();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [predictionList, setPredictionList] = useState([]);

  const navigate = useNavigate();

  const { resultBasketOrder } = useSelector(
    ({ basketOrderReducer }) => basketOrderReducer
  );

  const [usage, setUsage] = useState(false);
  const [usage2, setUsage2] = useState(false);

  const { resultPreviewProductVideo } = useSelector(
    ({ previewProductVideoReducer }) => previewProductVideoReducer
  );

  const resultVideoUrl = resultPreviewProductVideo.find(
    (item) => item.productKey === "pp_prediction"
  );
  // const getUsageById = async () => {
  //   let objSearch = {
  //     type: "payment-prediction",
  //   };
  //   const result = await getUsageByIdFetch(objSearch);

  //   // console.log("getUsageByIdFetch : ", result)
  //   setUsage(result);

  //   let objSearch2 = {
  //     type: "used-prediction",
  //   };
  //   const result2 = await getUsageByIdFetch(objSearch2);
  //   // console.log("getUsageByIdFetch : ", result)
  //   setUsage2(result2);
  // };

  const getBaseApi = async () => {
    setLoading(true);
    const result = await getPredictionAllFetch();
    // console.log("result : ", result)

    if (result?.length > 0) {
      setPredictionSelected(result[0]);
    }

    setPredictionList(result);
    // await getUsageById();
    setLoading(false);
  };

  const [predictionSelected, setPredictionSelected] = useState(null);
  const [isReadyPayment, setIsReadyPayment] = useState(null);

  const [modalBasket, setModalBasket] = useState({
    isShow: false,
    data: null,
  });

  const [modalPromotion, setModalPromotion] = useState({
    isShow: false,
    data: null,
  });

  // const [modalVerify, setModalVerify] = useState({
  //     isShow: false,
  //     data: null
  // })

  const delectBasketOrderAlreadySelected = () => {
    let find = resultBasketOrder.find(
      (val) => val.predictionId === predictionSelected?.predictionId
    );
    if (!find) {
      return true;
    } else {
      return false;
    }
  };

  const reloadDetectPredictionAlreadyPurchased = async () => {
    if (user?.uid && predictionSelected?.predictionId) {
      // let result = await detectPredictionAlreadyPurchased(
      //   user?.uid,
      //   predictionSelected?.predictionId
      // );
      // setIsReadyPayment(result);
    }
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  useEffect(() => {
    reloadDetectPredictionAlreadyPurchased();
  }, [predictionSelected?.predictionId]);

  // console.log("predictionSelected : ", predictionSelected)
  // console.log("resultBasketOrder : ", resultBasketOrder)
  if (loading) return <LoadingPage />;

  return (
    <>
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={"โหรทำนาย ออนไลน์"} />
      <CustomVideo video={`${serverUrl}/${resultVideoUrl.videoUrl}`} />
      <div>
        <ProductList />
      </div>

      <Footer />

      {/* <Modal
            maskClosable={false}
            title={<span>ดำเนินการชำระเงิน</span>}
            visible={modalBasket.isShow}
            width={400}
            onCancel={() => {
              // close modal
              setModalBasket({
                isShow: false,
                data: null,
              });
            }}
            footer={[]}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                <Button
                  type="primary"
                  style={{ width: 120 }}
                  onClick={() => {
                  
                    setModalBasket({
                      isShow: false,
                      data: null,
                    });
                  }}
                >
                  <label style={{ cursor: "pointer" }}>ช้อปปิ้งต่อ</label>
                </Button>
              </div>
    
              <div style={{ paddingLeft: 6, paddingRight: 6 }}>
                <Button
                  type="primary"
                  style={{ width: 120 }}
                  onClick={() => {
                  
                    navigate("/payment");
                  }}
                >
                  <label style={{ cursor: "pointer" }}>ชำระเงิน</label>
                </Button>
              </div>
            </div>
          </Modal>
    
          <Modal
            maskClosable={false}
            title={<span>โปรโมชั่น</span>}
            visible={modalPromotion.isShow}
            width={590}
            onCancel={() => {
              // close modal
              setModalPromotion({
                isShow: false,
                data: null,
              });
            }}
            footer={[]}
          >
            <Row gutter={[16, 0]}>
              <Col span={24}>
                <label>
                  {modalPromotion?.data?.title
                    ? parse(modalPromotion.data.title)
                    : []}
                </label>
              </Col>
    
              <Col span={24}>
                <label>
                  {modalPromotion?.data?.promotion
                    ? parse(modalPromotion.data.promotion)
                    : "-"}
                </label>
              </Col>
            </Row>
          </Modal> */}

      {/* <Modal
                    maskClosable={false}
                    title={<span>คุณยังไม่ได้ยืนยันตัวตน</span>}
                    visible={modalVerify.isShow}
                    width={300}
                    onCancel={() => {
                        // close modal
                        setModalVerify({ isShow: false, data: null })
                    }}
                    footer={[
                        <Button
                            type="primary"
                            onClick={() => {
                           
                                setTimeout(function () {
                                    navigate("/account?tab=verifyIdentity")
                                    window.scrollTo(0, 0)
                                }, 1000)
                            }}
                        >
                            ยืนยันตัวตน
                        </Button>
                    ]}
                >
                    <label>โปรดยืนยันตัวตน ก่อนชำระเงินซื้อคอร์ส</label>
                </Modal> */}
    </>
  );
}
