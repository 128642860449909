import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./css/index.css";
import LoadingPage from "../../../../common/components/loading/page";
import { serverUrl } from "../../../../constants";
import axios from "axios";
import parse from "html-react-parser";

const ChooseWe = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [chooseWes, setChooseWes] = useState([]);
  const imageUrl = "/assets/images/page/page_home/choose_we_main_bg.jpg";
  const getChooseWe = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${serverUrl}/api/v1/why_choose_us/get-why-choose-us-all`
      );
      const data = response.data;

      if (data.isSuccess) {
        const result = data.formData;

        setChooseWes(result);
      }
    } catch (error) {
      // Handle errors
      console.error("Error fetching choose we:", error);
      throw error;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getChooseWe();
  }, []);
  if (isLoading) return <LoadingPage />;
  return (
    <div className="choose-we">
      <div className="container">
        <h2 style={{ fontWeight: "bolder", fontSize: 36 }}>
          ทำไมต้องเลือกเรา ?
        </h2>
        <Row gutter={[24, 48]}>
          {chooseWes?.map((chooseWe) => (
            <Col sm={24} md={12} key={chooseWe.whyChooseUsId}>
              <div className="img-wrapper">
                <div
                  className="img-bg"
                  style={{
                    backgroundImage: `url(${serverUrl}/${chooseWe.imageUrl})`,
                  }}
                >
                  {/**Icon image */}
                  {/* <img
                    src={`${serverUrl}/${chooseWe.imageUrl}`}
                    width={107}
                    height={107}
                    alt="icon-cw"
                  /> */}
                </div>
              </div>

              <h3 style={{ fontWeight: "bolder" }}>{chooseWe.title}</h3>
              {parse(chooseWe.subTitle)}
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default ChooseWe;
