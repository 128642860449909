import { httpClient } from "../../../../utils/HttpClient";

export const getUserByUidFetch = async (uid) => {
  try {
    const result = await httpClient.get(`auth/get-user-by-uid?uid=${uid}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return true;
  }
};
