import React from "react";
import { serverUrl } from "../../../constants";

const UserManualVideo = ({ title, videoUrl }) => {
  return (
    <>
      <div style={{ marginBottom: 6 }}>วิธี{title} เเบบละเอียด</div>
      <video
        src={`${serverUrl}/${videoUrl}`}
        width="100%"
        height="auto"
        controls
      />
    </>
  );
};

export default UserManualVideo;
