/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import { apiServerUrl, serverUrl } from "../../../constants";
import Video from "../../../common/components/video";
import { Button, Col, Divider, Form, Input, Row, Upload } from "antd";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Notification } from "../../../common/components/notification";
import firebase from "firebase/app";
import { getFeedbackByIdFetch, insertFeedbackFetch } from "../API";
import moment from "moment";

export default function FeedbackBoard(props) {
  const [form] = Form.useForm();

  const [myFeedback, setMyFeedback] = useState([]);

  const [feedbackImage, setFeedbackImage] = useState({
    loading: false,
    imageUrl: null,
  });

  const [feedbackVideo, setFeedbackVideo] = useState({
    loading: false,
    videoUrl: null,
  });

  const [detail, setDetail] = useState();
  const detailRef = useRef();

  const optionFeedbackImage = {
    name: "file",
    action: `${apiServerUrl}/upload/file`,
    data: {
      name: "my-user",
      path: "upload_file/image/feedback",
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length > 0) {
          setFeedbackImage({
            imageUrl: info.file.response.filePath,
            loading: false,
          });
        }
      } else {
        setFeedbackImage({
          loading: true,
          imageUrl: feedbackImage.imageUrl,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const optionFeedbackVideo = {
    name: "file",
    action: `${apiServerUrl}/upload/file`,
    data: {
      name: "my-user",
      path: "upload_file/video/feedback",
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length > 0) {
          setFeedbackVideo({
            videoUrl: info.file.response.filePath,
            loading: false,
          });
        }
      } else {
        setFeedbackVideo({
          loading: true,
          videoUrl: feedbackVideo.videoUrl,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดวิดีโอไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const setDefault = () => {
    form.resetFields();

    setFeedbackImage({
      loading: false,
      imageUrl: null,
    });

    setFeedbackVideo({
      loading: false,
      videoUrl: null,
    });

    setDetail(null);
    detailRef.current = null;
  };

  const onFinish = async (values) => {
    let obj = {
      uid: firebase?.auth()?.currentUser?.uid ?? null,
      title: values.title ? values.title : null,
      subTitle: values.subTitle ? values.subTitle : null,
      detail: detailRef.current ? detailRef.current : null,
      imageUrl: feedbackImage.imageUrl ? feedbackImage.imageUrl : null,
      videoUrl: feedbackVideo.videoUrl ? feedbackVideo.videoUrl : null,
      feedbackCategoryId: props?.feedbackCategoryId ?? null,
    };
    // console.log("onFinish : ", obj)

    const result = await insertFeedbackFetch(obj);
    if (result.isSuccess) {
      Notification("success", "สร้างสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
    }

    // reload
    getFeedbackById();

    setDefault();
  };

  const getFeedbackById = async () => {
    const result = await getFeedbackByIdFetch(
      firebase?.auth()?.currentUser?.uid ?? null,
      props?.feedbackCategoryId ?? null
    );
    // console.log("getFeedbackByIdFetch : ", result)
    setMyFeedback(result);
  };

  useEffect(() => {
    getFeedbackById();
  }, []);

  // console.log("props?.feedbackCategoryId : ", props?.feedbackCategoryId)

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "grid",
              alignItems: "flex-start",
              paddingBottom: 12,
            }}
          >
            <label
              style={{
                fontSize: 20,
                fontWeight: 600,
                paddingBottom: 12,
                textAlign: "center",
              }}
            >
              รับข้อเสนอเเนะ
            </label>
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
            >
              <Row gutter={[8, 8]}>
                {/* <Col xs={24} md={12} xl={12}>
                            <Form.Item
                                name="title"
                                label="หัวข้อที่เสนอเเนะ"
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดย่อย' }]}
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                    autoSize={{ minRows: 1, maxRows: 2 }}
                                    maxLength={700}
                                    showCount
                                />
                            </Form.Item>
                        </Col> */}

                <Col xs={24} md={24} xl={24}>
                  <Form.Item
                    name="subTitle"
                    label="ข้อเสนอเเนะ"
                    rules={[
                      { required: true, message: "กรุณากรอกข้อเสนอเเนะ" },
                    ]}
                  >
                    <Input.TextArea
                      style={{ width: "100%" }}
                      autoSize={{ minRows: 4, maxRows: 10 }}
                      maxLength={700}
                      showCount
                    />
                  </Form.Item>
                </Col>

                {/* <Col xs={24} md={24} xl={24}>
                            <Form.Item
                                name="detail"
                                label="รายละเอียดข้อที่เสนอเเนะ"
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            height: 350,
                                        },
                                    }}
                                    data={detail}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        detailRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col> */}

                {/* <Col xs={24} md={12} xl={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>เเนบรูปภาพ (ไม่บังคับ)</label>
                                {feedbackImage?.imageUrl ?
                                    <img
                                        style={{ borderRadius: 8, maxHeight: 207, border: "1px solid #EEEEEE" }}
                                        src={`${serverUrl}/${feedbackImage.imageUrl}`}
                                    />
                                    :
                                    <img
                                        style={{ width: "100%", borderRadius: 8 }}
                                        src={`./assets/images/default/df-img.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionFeedbackImage}
                                        accept='image/jpeg, image/png, image/jfif'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={feedbackImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col> */}

                {/* <Col xs={24} md={12} xl={12}>
                            <div style={{ display: "grid" }}>
                                <label style={{ paddingBottom: 6 }}>เเนบวิดีโอ (ไม่บังคับ)</label>
                                {feedbackVideo?.videoUrl ?
                                    <div style={{ backgroundColor: "black", borderRadius: 8 }}>
                                        <Video
                                            url={feedbackVideo.videoUrl}
                                            title={""}
                                            height={"100%"}
                                            width={"100%"}
                                        />
                                    </div>
                                    :
                                    <img
                                        style={{ width: "100%", borderRadius: 8 }}
                                        src={`./assets/images/default/df-vdo.png`}
                                    />
                                }
                                <div style={{ paddingTop: 12 }}>
                                    <Upload
                                        {...optionFeedbackVideo}
                                        accept='.mp4'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={{ showRemoveIcon: false }}
                                    >
                                        <Button
                                            type="default"
                                            style={{ width: "100%" }}
                                            icon={feedbackVideo.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดวิดีโอ</Button>
                                    </Upload>
                                </div>
                            </div>
                        </Col> */}

                <Col span={24}>
                  {/* <Divider style={{ marginTop: 12, marginBottom: 18 }} /> */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      size="middle"
                      onClick={() => {
                        form.submit();
                      }}
                    >
                      ส่งข้อมูล
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>

        {myFeedback?.length > 0 ? (
          <Col span={24} style={{ display: "grid" }}>
            <label style={{ paddingBottom: 12, fontWeight: 600 }}>
              ประวัติการส่งข้อเสนอเเนะของฉัน
            </label>

            {myFeedback?.map((item) => {
              return (
                <div style={{ paddingBottom: 16 }}>
                  <div
                    style={{
                      padding: 12,
                      height: 60,
                      borderRadius: 8,
                      backgroundColor: "#EEEEEE",
                    }}
                  >
                    <label>{item.subTitle}</label>
                  </div>
                  <label
                    style={{ float: "right", fontSize: 14, paddingTop: 4 }}
                  >
                    เมื่อวันที่{" "}
                    {moment(item.createdAt).format("DD/MM/YYYY HH:mm:ss")} (
                    {Number(moment(item.createdAt).format("YYYY")) + 543})
                  </label>
                </div>
              );
            })}
          </Col>
        ) : (
          []
        )}
      </Row>
    </>
  );
}
