import { httpClient } from "../../../../../utils/HttpClient";

const getPageContactFetch = async () => {
  try {
    const result = await httpClient.get(`contact/get-page-contact`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getPageContactFetch,

  // insert

  // update

  // delete
};
