import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPredictionByIdFetch = async (id) => {
    try {
        const result = await httpClient.get(`prediction/get-prediction-by-id/${id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getMandalaByIdFetch = async (key) => {
    try {
        const result = await httpClient.get(`mandala/get-mandala-by-id?key=${key}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getKhunHorByIdFetch = async (key) => {
    try {
        const result = await httpClient.get(`khun_hor/get-khun-hor-by-id?key=${key}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBirthdayUserByIdFetch = async (uid) => {
    try {
        const result = await httpClient.get(`auth/get-birthday-user-by-id?uid=${uid}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFrtuneTellerQuestionsMasterAllFetch = async () => {
    try {
        const result = await httpClient.get(`prediction/get-fortune-teller-questions-master-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFrtuneTellerQuestionsByIdFetch = async (masterId) => {
    try {
        const result = await httpClient.get(`prediction/get-fortune-teller-questions-by-id?masterId=${masterId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPredictionByIdFetch,
    getMandalaByIdFetch,    
    getKhunHorByIdFetch,
    getBirthdayUserByIdFetch,
    getFrtuneTellerQuestionsMasterAllFetch,
    getFrtuneTellerQuestionsByIdFetch
}