/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Col, Empty, Menu, Row } from "antd";
import { useDimensions } from "../../common/mamager/ScreenManager";
import Header from "../../common/components/header";
import { Content } from "antd/lib/layout/layout";
import Footer from "../../common/components/footer";
import LoadingPage from "../../common/components/loading/page";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import UserManualVideo from "./component/UserManualVideo";
import { getUserManualFetch } from "./API";

const UserManual = (props) => {
  const { width } = useDimensions();
  const tab = null;
  const [selectedMenuItem, setSelectedMenuItem] = useState();
  const [manuals, setManuals] = useState([]);
  const [loading, setLoading] = useState(false);

  const getUserManual = async () => {
    setLoading(true);
    const result = await getUserManualFetch()
    setSelectedMenuItem(result[0].title);
    setManuals(result);
    setLoading(false);
  };

  useEffect(() => {
    getUserManual();
  }, []);

  const componentsSwitch = (type) => {
    // Find the manual based on the selected type
    const manual = manuals.find((manual) => manual.title === type);

    if (!manual) return null; // If no manual is found, return null

    return <UserManualVideo title={manual.title} videoUrl={manual.videoUrl} />;
  };
  return (
    <>
      <Header
        firebase={props.firebase}
        authorized={props.authorized}
        user={props.user}
      />{" "}
      <BreadcrumbPage pageCurrentName={"สอนการใช้งานเว็บไซต์"} />
      <article>
        <Content style={{ backgroundColor: "#F5F5F5", minHeight: "100vh" }}>
          <div
            style={
              width > 1500
                ? {
                  backgroundColor: "white",
                  marginLeft: "5%",
                  marginRight: "5%",
                  minHeight: "100vh",
                }
                : width > 912 && width <= 1500
                  ? {
                    backgroundColor: "white",
                    marginLeft: "5%",
                    marginRight: "5%",
                    minHeight: "100vh",
                  }
                  : {
                    backgroundColor: "white",
                    marginLeft: "0%",
                    marginRight: "0%",
                    minHeight: "100vh",
                  }
            }
          >
            <Row style={{ backgroundColor: "white", height: "100%" }}>
              <Col xs={4} sm={6} md={8} xl={4}>
                <Menu
                  mode="inline"
                  defaultSelectedKeys={[tab ? tab : "profile"]}
                  defaultOpenKeys={[tab ? tab : "profile"]}
                  style={{
                    height: "100%",
                    borderRight: 0,
                    padding: 0,
                    minHeight: "100vh",
                  }}
                  theme="dark"
                  selectedKeys={selectedMenuItem}
                  onClick={(e) => setSelectedMenuItem(e.key)}
                >
                  {manuals.length > 0 &&
                    manuals.map((manual) => (
                      <Menu.Item key={manual.title} title={manual.title}>
                        {manual.title}
                      </Menu.Item>
                    ))}
                </Menu>
              </Col>

              <Col
                xs={20}
                sm={16}
                md={16}
                xl={20}
                style={{ borderLeft: "1px solid #ECECEC" }}
              >
                <div style={{ padding: width > 576 ? 24 : 12, minHeight: 500 }}>
                  {!loading ? (
                    componentsSwitch(selectedMenuItem)
                  ) : (
                    <LoadingPage loading={loading} />
                  )}
                  {manuals.length <= 0 && (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      style={{ textAlign: "center" }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </article>
      <Footer btnAutnActive={false} />
    </>
  );
};

export default UserManual;
