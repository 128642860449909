/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */


import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "../../common/mamager/ScreenManager";
import Header from "../../common/components/header";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import Footer from "../../common/components/footer";
import LoadingPage from "../../common/components/loading/page";
import { serverUrl } from "../../constants";
import { getCourseAllFetch } from "./API";
import { useDispatch, useSelector } from "react-redux";
import CustomVideo from "../../common/components/videoJS";
import ProductList from "./components/ProductList";
import "../../modules/course/css/course.css";
import { detectCourseAlreadyPurchased } from "../../common/mamager/DetectManager";

export default function Course({ authorized, firebase, user, previewProduct }) {
  const { width } = useDimensions();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [courseList, setCourseList] = useState([]);

  const navigate = useNavigate();

  const { resultBasketOrder } = useSelector(
    ({ basketOrderReducer }) => basketOrderReducer
  );

  const { resultPreviewProductVideo } = useSelector(({ previewProductVideoReducer }) => previewProductVideoReducer)

  const resultVideoUrl = resultPreviewProductVideo ?
    resultPreviewProductVideo?.find((item) => item.productKey === "pp_course")
    :
    previewProduct?.find((item) => item.productKey === "pp_course")

  const [courseSelected, setCourseSelected] = useState(null);
  const [isReadyPayment, setIsReadyPayment] = useState(null);

  // const [modalBasket, setModalBasket] = useState({
  //   isShow: false,
  //   data: null,
  // });

  // const [modalVerify, setModalVerify] = useState({
  //     isShow: false,
  //     data: null
  // })

  // const delectBasketOrderAlreadySelected = () => {
  //   let find = resultBasketOrder.find(
  //     (val) => val.courseId === courseSelected?.courseId
  //   );
  //   if (!find) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const reloadDetectCourseAlreadyPurchased = async () => {
    // if (user?.uid && courseSelected?.courseId) {
    //   let result = await detectCourseAlreadyPurchased(
    //     user?.uid,
    //     courseSelected?.courseId
    //   );
    //   setIsReadyPayment(result);
    // }
  };

  const getBaseApi = async () => {
    setLoading(true);
    const result = await getCourseAllFetch();
    if (result?.length > 0) {
      setCourseSelected(result[0]);
    }

    setCourseList(result);
    setLoading(false);
  };

  useEffect(() => {
    getBaseApi();
  }, []);

  useEffect(() => {
    reloadDetectCourseAlreadyPurchased();
  }, [courseSelected?.courseId]);
  if (loading) return <LoadingPage />;

  return (
    <>
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={"คอร์สเรียน ออนไลน์"} />
      <CustomVideo video={`${serverUrl}/${resultVideoUrl.videoUrl}`} />
      <div>
        <ProductList />
      </div>

      <Footer />

      {/* <Modal
        maskClosable={false}
        title={<span>ดำเนินการชำระเงิน</span>}
        visible={modalBasket.isShow}
        width={400}
        onCancel={() => {
          // close modal
          setModalBasket({
            isShow: false,
            data: null,
          });
        }}
        footer={[]}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ paddingLeft: 6, paddingRight: 6 }}>
            <Button
              type="primary"
              style={{ width: 120 }}
              onClick={() => {
                setModalBasket({
                  isShow: false,
                  data: null,
                });
              }}
            >
              <label style={{ cursor: "pointer" }}>ช้อปปิ้งต่อ</label>
            </Button>
          </div>

          <div style={{ paddingLeft: 6, paddingRight: 6 }}>
            <Button
              type="primary"
              style={{ width: 120 }}
              onClick={() => {
            
                navigate("/payment");
              }}
            >
              <label style={{ cursor: "pointer" }}>ชำระเงิน</label>
            </Button>
          </div>
        </div>
      </Modal>*/}
    </>
  );
}
