/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import "./css/index.css";
import { useEffect, useState, useRef } from "react";
import Header from "../../common/components/header";
import Footer from "../../common/components/footer";
import LoadingPage from "../../common/components/loading/page";
import { Row, Col, Modal, Flex, Button } from "antd";
import { useDimensions } from "../../common/mamager/ScreenManager";
import BreadcrumbPage from "../../common/components/breadcrumbPage";
import { getEBookAllFetch, getEbookVideoUrl } from "./API";
import parse from "html-react-parser";
import { serverUrl } from "../../constants";
import CustomVideo from "../../common/components/videoJS";
import ProductList from "./components/ProductList";
import { useSelector } from "react-redux";

export default function EBook({ authorized, firebase, user }) {
  const [loading, setLoading] = useState(false);
  const { width } = useDimensions();

  const [ebook, setEBook] = useState(null);

  const getEBook = async () => {
    let result = await getEBookAllFetch();
    // console.log("result : ", result)
    setEBook(result);
  };

  const [modalReadMore, setModalReadMore] = useState({
    title: null,
    isShow: false,
    data: null,
  });

  const { resultPreviewProductVideo } = useSelector(
    ({ previewProductVideoReducer }) => previewProductVideoReducer
  );

  const resultVideoUrl = resultPreviewProductVideo.find(
    (item) => item.productKey === "pp_ebook"
  );

  // const genPrice = (data) => {
  //     if (data.isDiscount) {
  //         return data.price - data.discount
  //     } else {
  //         return data.price
  //     }
  // }

  const baseApi = async () => {
    setLoading(true);
    await getEBook();

    setLoading(false);
  };

  useEffect(() => {
    baseApi();
  }, []);

  const [labelText, setLabelText] = useState("VeryLongTextHere");
  const maxLength = 10;

  useEffect(() => {
    if (labelText.length > maxLength) {
      setLabelText(labelText.substring(0, maxLength) + "...");
    }
  }, []);

  function ShortenedLabel({ text, maxLength }) {
    const [shortenedText, setShortenedText] = useState(text);

    useEffect(() => {
      if (text.length > maxLength) {
        setShortenedText(text.substring(0, maxLength) + "...");
      } else {
        setShortenedText(text);
      }
    }, [text, maxLength]);

    return <label>{shortenedText}</label>;
  }
  if (loading) return <LoadingPage />;
  return (
    <>
      <Header firebase={firebase} user={user} />
      <BreadcrumbPage pageCurrentName={"E-Book"} />
      <CustomVideo video={`${serverUrl}/${resultVideoUrl.videoUrl}`} />
      <div>
        <ProductList />
      </div>
      <Footer />

      <Modal
        maskClosable={false}
        title={<span>{modalReadMore.title}</span>}
        visible={modalReadMore.isShow}
        width={590}
        onCancel={() => {
          // close modal
          setModalReadMore({
            isShow: false,
            data: null,
          });
        }}
        footer={
          [
            // <Button
            //     type="primary"
            //     onClick={() => {
            //     }}
            // >
            //     ปิด
            // </Button>
          ]
        }
      >
        <Row gutter={[0, 12]}>
          <Col span={24}>
            <div
              style={{
                display: width > 500 ? "flex" : "grid",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              {modalReadMore?.data?.imgCoverUrl ? (
                <img
                  src={`${serverUrl}/${modalReadMore?.data?.imgCoverUrl}`}
                  style={{ width: 150, paddingTop: 24 }}
                  alt={"Witte-bangkok EBook"}
                />
              ) : (
                []
              )}
              <label style={{ paddingLeft: 20 }}>
                {modalReadMore?.data?.detail
                  ? parse(modalReadMore?.data?.detail)
                  : []}
              </label>
            </div>
          </Col>

          <Col span={24}>
            <div style={{ display: "grid" }}>
              <label style={{ paddingBottom: 6, fontWeight: 600 }}>
                ตัวอย่าง eBook
              </label>
              <iframe
                src={`${serverUrl}/${modalReadMore?.data?.pdfURL}`}
                style={{ width: "100%", height: 300 }}
              />
            </div>
          </Col>

          <Col span={24}>
            <Button
              type="primary"
              onClick={() => {
                window.open(modalReadMore?.data?.link);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <label style={{ cursor: "pointer" }}>สั่งซื้อ eBook นี้</label>
              </div>
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
