import { server } from "../../../constants";
import { httpClient } from "../../../utils/HttpClient";

const getDetectUsernameDuplicateFetch = async (username) => {
  try {
    const result = await httpClient.get(
      `auth/get-detect-username-duplicate?username=${username}`
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getDetectEmailDuplicateFetch = async (email) => {
  try {
    const result = await httpClient.get(`auth/get-detect-email-duplicate?email=${email}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getDetectPhoneDuplicateFetch = async (phone) => {
  try {
    const result = await httpClient.get(`auth/get-detect-phone-duplicate?phone=${phone}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getProvincesFetch = async () => {
  try {
    const result = await httpClient.get(`auth/get-provinces-all`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getCountryFetch = async () => {
  try {
    const result = await httpClient.get(`auth/get-country-all`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getPrefixAllFetch = async () => {
  try {
    const result = await httpClient.get(`auth/get-prefix-all`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const insertUserFetch = async (value) => {
  try {
    const result = await httpClient.post(`user/insert-users`, { value });
    if (result.data.isSuccess) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getDetectUsernameDuplicateFetch,
  getDetectEmailDuplicateFetch,
  getDetectPhoneDuplicateFetch,
  getProvincesFetch,
  getPrefixAllFetch,
  getCountryFetch,

  // insert
  insertUserFetch,

  // update

  // delete
};
