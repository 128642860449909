import { server } from "../../../constants";
import { httpClient } from "../../../utils/HttpClient";

const getExamRoomAllFetch = async (value) => {
  try {
    const result = await httpClient.get(`exam/get-exam-room-all?uid=${value.uid}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const getExamRoomByIdFetch = async (value) => {
  try {
    const result = await httpClient.get(`exam/get-exam-room-by-id?examRoomId=${value.examRoomId}&uid=${value.uid}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const getPermissionExamByIdFetch = async (uid) => {
  try {
    const result = await httpClient.get(`exam/get-permission-exam-by-id?uid=${uid}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const getQuestionListByIdFetch = async (examId) => {
  try {
    const result = await httpClient.get(`exam/get-question-list-by-id?examId=${examId}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const getAnswerListByIdFetch = async (examId) => {
  try {
    const result = await httpClient.get(`exam/get-answer-list-by-id?examId=${examId}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const getExamInspectLogByIdFetch = async (examRoomId) => {
  try {
    const result = await httpClient.get(``);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const updateExamRoomByIdFetch = async (value) => {
  try {
    const result = await httpClient.put(`exam/update-exam-room-by-id`, {value});
    if (result.data.isSuccess) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {}
};

const updateAnswerListByIdFetch = async (value) => {
  try {
    const result = await httpClient.put(`exam/update-answer-list-by-id`, {
      value,
    });
    if (result.data.isSuccess) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {}
};

export {
  // get
  getExamRoomByIdFetch,
  getExamRoomAllFetch,
  getPermissionExamByIdFetch,
  getQuestionListByIdFetch,
  getAnswerListByIdFetch,
  getExamInspectLogByIdFetch,

  // insert

  // update
  updateExamRoomByIdFetch,
  updateAnswerListByIdFetch

  // delete
};
