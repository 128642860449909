"use client";
import React from "react";
import videojs from "video.js";
import VideoJS from "./Video";
import "./video.css";

const CustomVideo = ({ video }) => {
  //   const [video, setVideo] = useState<string>();
  //   const [image, setImage] = useState<string>();
  //   const [isLoading, setIsLoading] = useState<boolean>(true);
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: false,
    muted: false, // Mute the video
    loop: true, // Mute the video
    sources: [
      {
        src: video,
        type: "video/mp4",
      },
    ],
    // controlBar: {
    //   children: [
    //     "playToggle", // Play button
    //   ],
    // },
  };

  const myClickHandler = () => {
    const player = playerRef.current;

    if (player) {
      // if (player.paused()) {

      //   player.play(); // Play the video if it's paused
      // } else {

      //   player.pause(); // Pause the video if it's playing
      // }
      if (!player.muted()) {
        player.muted(true);
        // player.play(); // Play the video if it's paused
      } else {
        player.muted(false);
        // player.pause(); // Pause the video if it's playing
      }
    }
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // Bind the click event to the video element
    player.on("click", myClickHandler);

    // Handle other player events
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  return (
    <>
      {/* {!video && !image && (
        <Image
          src={"/assets/images/home/default_video.jpg"}
          alt="default_video"
          width={1366}
          height={546}
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      )} */}
      {video && (
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      )}
      {/* {typeId === 1 && image && (
        <Image
          src={image}
          alt="intro"
          width={1366}
          height={546}
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
        />
      )} */}
    </>
  );
};

export default CustomVideo;
