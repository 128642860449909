/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Upload,
  Image,
  Divider,
  Select,
  Input,
} from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { Icon } from "@iconify/react";
import { AiOutlineUpload } from "react-icons/ai";
import { useDimensions } from "../../../../common/mamager/ScreenManager";
import LoadingPage from "../../../../common/components/loading/page";
import { updateUser } from "../../API";
import { Notification } from "../../../../common/components/notification";
import { resizeImage } from "../../../../common/mamager/ImageMamager";
import { getUserByUidFetch } from "../../../../common/components/header/API";
import firebase from "../../../../config/firebase-config";
// import { apiStorageUrl } from "../../../../constants"
// import { detectPhone } from "../../../../common/mamager/DetectManager"

const { Option } = Select;

export default function Profile(props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const { width } = useDimensions();
  const [form] = Form.useForm();

  const [courseCoverImage, setCourseCoverImage] = useState({
    loading: false,
    imageProfile: user ? (user.imageProfile ? user.imageProfile : null) : null,
  });

  const onFinish = async (values) => {
    setLoading(true);

    let objUser = {
      uid: user ? (user.uid ? user.uid : null) : null,
      provinceId: values.province ? values.province : null,
      countryId: values.country ? values.country : null,
      phone: values.phone ? values.phone : null,
      imageProfile: courseCoverImage.imageProfile
        ? courseCoverImage.imageProfile
        : null,
    };
    // console.log("objUser : ", objUser)
    const result = await updateUser(objUser);

    if (result.isSuccess) {
      Notification("success", "เเจ้งเตือน!", "บันทึกสำเร็จ");
      handleReload();
    } else {
      Notification("error", "ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    }

    setLoading(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  //Old upload image version
  // const handleChange = (info) => {
  //   if (info.file.status !== "uploading") {
  //     resizeImage(info.file.originFileObj, 300, 300).then((res) => {
  //       getBase64(res, (imageProfile) => {
  //         setCourseCoverImage({
  //           loading: false,
  //           imageProfile,
  //         });
  //       });
  //     });
  //   } else {
  //     setCourseCoverImage({ loading: true });
  //   }

  //   if (info.file.status === "done") {
  //     Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
  //   } else if (info.file.status === "error") {
  //     Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ");
  //   }
  // };

  const handleChange = (info) => {
    if (info.file.status !== "uploading") {
      resizeImage(info.file.originFileObj, 300, 300).then((res) => {
        getBase64(res, (imageProfile) => {
          setCourseCoverImage({
            loading: false,
            imageProfile,
          });

          // Always show success notification
          Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
        });
      });
    } else {
      setCourseCoverImage({ loading: true });
    }
  };

  const getUserByUid = async () => {
    const result = await getUserByUidFetch(firebase.auth().currentUser?.uid);
    form.setFieldsValue({
      province: result?.province?.provinceId,
      country: result?.country?.countryId,
      phone: result?.phone,
    });
    setCourseCoverImage({
      loading: false,
      imageProfile: result
        ? result.imageProfile
          ? result.imageProfile
          : null
        : null,
    });
    // console.log("getUserByUidFetch : ", result, user)
    setUser(result);
  };

  const handleReload = () => {
    getUserByUid();
    props.handleReloadAccount();
  };
  useEffect(() => {
    getUserByUid();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      //   initialValues={{
      //     province: user.province?.provinceId,
      //     country: user.country?.countryId,
      //     phone: user.phone,
      //   }}
      onFinish={onFinish}
    >
      {!loading ? (
        <Row gutter={[16, 0]}>
          <Col xs={24} xl={8}>
            <Row>
              <Col span={24}>
                <center>
                  {courseCoverImage.imageProfile ? (
                    <Image
                      style={{
                        height: "160px",
                        width: "160px",
                        borderRadius: "50%",
                      }}
                      src={courseCoverImage.imageProfile}
                      preview={false}
                    />
                  ) : (
                    <Image
                      style={{
                        height: "160px",
                        width: "160px",
                        borderRadius: "50%",
                      }}
                      src={
                        user.imageProfile !== null
                          ? user.imageProfile
                          : `./assets/images/profile/personDefault.jpg`
                      }
                      preview={false}
                    />
                  )}
                </center>
              </Col>

              <Col span={24} style={{ paddingTop: 15 }}>
                <center>
                  <Form.Item name="uploadCourseCoverImage">
                    <ImgCrop
                      modalTitle={
                        <label style={{ fontWeight: 100 }}>
                          ปรับเเต่งรูปภาพ
                        </label>
                      }
                      modalOk={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AiOutlineUpload style={{ width: 20, height: 20 }} />
                          <label style={{ paddingLeft: 10, cursor: "pointer" }}>
                            อัพโหลด
                          </label>
                        </div>
                      }
                      modalCancel={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Icon
                            icon="mdi:cancel"
                            style={{ width: 20, height: 20 }}
                          />
                          <label style={{ paddingLeft: 10, cursor: "pointer" }}>
                            ยกเลิก
                          </label>
                        </div>
                      }
                      aspect={300 / 300}
                    >
                      <Upload
                        accept=".jpeg, .jpg, .png"
                        maxCount={1}
                        showUploadList={false}
                        // action={`${apiServerUrl}/upload/file`}
                        action={null}
                        onChange={handleChange}
                      >
                        <Button
                          type="primary"
                          style={{ width: 200 }}
                          icon={
                            courseCoverImage.loading ? (
                              <LoadingOutlined />
                            ) : (
                              <UploadOutlined />
                            )
                          }
                        >
                          อัพโหลด
                        </Button>
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </center>
              </Col>
            </Row>
          </Col>

          <Col xs={24} xl={16}>
            <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                ชื่อผู้ใช้งาน
              </Col>
              <Col xs={24} md={18} xl={18}>
                {user.username}
              </Col>

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                อีเมล
              </Col>
              <Col xs={24} md={18} xl={18}>
                <a href={"mailto: " + user.email} style={{ color: "black" }}>
                  {user.email}
                </a>
              </Col>

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                เบอร์โทร
              </Col>
              <Col xs={24} md={18} xl={18}>
                <Form.Item
                  //   label="เบอร์โทร"
                  name="phone"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "กรุณากรอกเบอร์โทร" }]}
                >
                  <Input placeholder="กรอกเบอร์โทร" style={{ width: 180 }} />
                </Form.Item>
              </Col>

              <Divider style={{ marginTop: 1, marginBottom: 1 }} />

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                ชื่อ
              </Col>
              <Col xs={24} md={18} xl={18}>
                {user.firstname && user.lastname ?
                  <>
                    {user.firstname} {user.lastname}
                  </>
                  :
                  "-"
                }
              </Col>

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                วันเกิด
              </Col>
              <Col xs={24} md={18} xl={18}>
                {user.dateOfBirth && user.monthOfBirth && user.yearOfBirth ?
                  <>
                    {user.dateOfBirth +
                      "/" +
                      (user.monthOfBirth > 9
                        ? user.monthOfBirth
                        : "0" + user.monthOfBirth) +
                      "/" +
                      user.yearOfBirth +
                      " " +
                      user.timeOfBirth}
                  </>
                  :
                  "-"
                }
              </Col>

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                เพศ
              </Col>
              <Col xs={24} md={18} xl={18}>
                {user.prefix?.name ?
                  <>
                    {user.prefix?.name}
                  </>
                  :
                  "-"
                }
              </Col>

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                สถานที่ (จังหวัด)
              </Col>
              <Col xs={24} md={18} xl={18}>
                {/* {console.log("DD : ", user)} */}
                {/* {user.province?.provinceNameTH} */}
                <Form.Item
                  // label="สถานที่ (จังหวัด)"
                  name="province"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "กรุณาเลือกจังหวัด" }]}
                >
                  <Select
                    showSearch
                    style={{ width: 180 }}
                    placeholder="จังหวัด"
                    optionFilterProp="children"
                    allowClear
                  >
                    {props.provincesData.map((val, index) => (
                      <Option key={index} value={val.provinceId}>
                        {val.provinceNameTH}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={24}
                md={6}
                xl={6}
                style={{ textAlign: width > 808 ? "right" : "left" }}
              >
                ประเทศ
              </Col>
              <Col xs={24} md={18} xl={18}>
                {/* {console.log("DD : ", user)} */}
                {/* {user.province?.provinceNameTH} */}
                <Form.Item
                  // label="สถานที่ (จังหวัด)"
                  name="country"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "กรุณาเลือกประเทศ" }]}
                >
                  <Select
                    showSearch
                    style={{ width: 180 }}
                    placeholder="ประเทศ"
                    optionFilterProp="children"
                    allowClear
                  >
                    {props.countryData.map((val, index) => (
                      <Option key={index} value={val.countryId}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            span={24}
            style={{ textAlign: "center", borderTop: "1px solid #eeeeee" }}
          >
            <div style={{ paddingTop: 15 }}>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon
                    icon="bx:save"
                    style={{ width: 19, height: 19, color: "white" }}
                  />
                  <label style={{ paddingLeft: 10, cursor: "pointer" }}>
                    บันทึกข้อมูล
                  </label>
                </div>
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <LoadingPage loading={loading} height={190} />
      )}
    </Form>
  );
}
