/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from 'react'
import { BiPlayCircle } from "react-icons/bi"
import {
    List,
    Image,
    Empty,
    Row,
    Col,
    Tooltip
} from 'antd'
import '../../css/video.css'
import moment from 'moment'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { serverUrl } from '../../../../constants'

export default function CourseVideo(props) {
    const [sizeLayout, setsizeLayout] = useState()

    const { width } = useDimensions()

    const [videoWatchingNow, setVideoWatchingNow] = useState(0)

    const onClickWatchVideo = async (item, index) => {
        setVideoWatchingNow(item.courseVideoId)
        props.handleVideoCourseCurrent(item)
    }

    const genColorPaidVideo = (item, index) => {
        let codeColor = ""
        if (item.isPaymentByVideo) {
            if (item.paymentInfo?.length > 0) {
                codeColor = "#390000"
            } else {
                // detect paid seccess
                if (item.paymentInfo[0]?.payment_oder?.paymentStatusId === 2) {
                    codeColor = " #392f00"
                }
            }
        }
        return codeColor
    }

    const genTitleName = (data) => {
        return (
            <label
                style={{
                    color: 'white',
                    marginTop: -4,
                    margin: '0px 0px 0px 0px',
                    height: 45,
                    cursor: 'pointer',
                    width: "100%",

                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    maxHeight: 45,
                    textOverflow: "ellipsis",
                    overflow: "hidden",

                    paddingRight: (props.pageName === "classroom-course-study" ? 10 : 0)
                }}>
                {data}
            </label>
        )
    }

    const genVideo = (videoList, key) => {
        return (
            <div style={{ backgroundColor: "black", color: "white" }}>
                {videoList?.length > 0 ?
                    <List
                        itemLayout="horizontal"
                        dataSource={videoList}
                        renderItem={(item, index) => {
                            return (
                                <Row
                                    style={{
                                        width: "100%",
                                        cursor: 'pointer',
                                        borderBottom: (videoList?.length === (index + 1) ? '' : '1px solid #696969'),
                                        backgroundColor: (videoWatchingNow === item.courseVideoId ? "#3D3D3D" : genColorPaidVideo(item, index))
                                    }}
                                    className={"video-item-group"}
                                >
                                    <Col style={{ width: "100%" }}>
                                        <List.Item
                                            style={{
                                                paddingLeft: 15,
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                height: 75
                                            }}
                                            onClick={async () => await onClickWatchVideo(item, index)}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Row>
                                                        <Col span={24} style={{ marginLeft: -12 }}>
                                                            <div style={{ paddingLeft: 6 }}>
                                                                {(item.createdAt ? (moment().diff(moment(item.createdAt, "YYYY-MM-DD HH:mm:ss"), "day") <= 30) : false) ?
                                                                    <div style={{ position: 'absolute', top: 0, left: 6, zIndex: 1, marginTop: -10 }}>
                                                                        <label style={{ color: "white", fontWeight: 'bold', backgroundColor: "red", borderRadius: '0px 0px 20px 0px', paddingRight: 8 }}>ใหม่!</label>
                                                                    </div>
                                                                    : []
                                                                }

                                                                <div style={{ position: 'relative', textAlign: 'center' }}>
                                                                    <div>
                                                                        <BiPlayCircle
                                                                            style={{
                                                                                width: 30,
                                                                                height: 30,
                                                                                position: 'absolute',
                                                                                zIndex: 1,
                                                                                top: "40%", // ทำให้ปุ่มอยู่กึ่งกลางตามแนวดิ่ง
                                                                                left: "57%", // ทำให้ปุ่มอยู่กึ่งกลางตามแนวนอน
                                                                                transform: "translate(-50%, -50%)",
                                                                                color: 'white',
                                                                                opacity: 0.6
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <Image
                                                                        style={{ width: 100, height: 60, marginTop: -10, border: "1px solid white" }}
                                                                        src="error"
                                                                        fallback={`${serverUrl}/${item.imgCoverUrl}`}
                                                                        preview={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }

                                                title={
                                                    <div style={{ fontWeight: 100 }}>
                                                        {item.title?.length > 75 ?
                                                            <Tooltip
                                                                color={"white"}
                                                                placement="left"
                                                                title={<div style={{ color: 'black' }}>{item.title}</div>}
                                                                style={{ margin: -3 }}
                                                            >
                                                                {genTitleName(item.title)}
                                                            </Tooltip>
                                                            :
                                                            genTitleName(item.title)
                                                        }
                                                    </div>
                                                }
                                            />
                                        </List.Item>
                                    </Col>
                                </Row>
                            )
                        }
                        }
                    />
                    :
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        imageStyle={{
                            height: 60,
                        }}
                        style={{ width: '100%' }}
                        description={
                            <span style={{ color: 'white' }}>
                                ไม่มีวิดีโอคอร์ส
                            </span>
                        }
                    >
                    </Empty>
                }
            </div>
        )
    }

    useEffect(() => {
        // set default size
        setsizeLayout(width < 992 ? 700 : 905)

        // set default focus 
        if (videoWatchingNow === 0) {
            setVideoWatchingNow(props.videoCourse[0]?.courseVideoId)
        }
    }, [])

    return (
        <div
            style={{
                scrollbarColor: 'black',
                overflow: (width < 992 ? 'hidden' : 'auto'),
                height: sizeLayout,
                width: 'auto',
                overflowX: 'hidden',
                backgroundColor: '#191919',
                fontSize: 14
            }}
        >
            {genVideo(props.videoCourse, null)}
        </div>
    )
}
