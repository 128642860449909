import { httpClient } from "../../../utils/HttpClient"

const getUserManualFetch = async (value) => {
    try {
        const result = await httpClient.get(`user_manual/get-user-manual-all`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getUserManualFetch

    // insert

    // update

    // delete

}