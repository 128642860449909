/* eslint-disable react-hooks/exhaustive-deps */
import "./styles/App.css";
import "./styles/App.less";

import Home from "./modules/home";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ThemeProvider } from '@mui/material/styles'
// import themeMUI from "./styles/themeMUI"
import themeAntd from "./styles/themeAntd";
import Contract from "./modules/contract";
import Login from "./modules/login";
import Register from "./modules/register";
import LoginDetect from "./modules/login/components/loginDetect";
import Account from "./modules/account";
import Course from "./modules/course";
import Payment from "./modules/payment";
import Classroom from "./modules/classroom";
import ClassroomStudy from "./modules/classroomStudy";
import Prediction from "./modules/prediction";
import PredictionRoom from "./modules/predictionRoom";
import PredictionOpen from "./modules/predictionOpen";
import EBook from "./modules/ebook";
import Exam from "./modules/exam";
import CourseDetail from "./modules/courseDetail";
import PredictionDetail from "./modules/predictionDetail";
import EBookDetail from "./modules/ebookDetail";
import Suggestion from "./modules/suggestion";
import UserManual from "./modules/userManual";
import Blog from "./modules/blog";
import { useDispatch } from "react-redux";
import { getPreviewProductVideoAllFetch } from "./modules/home/API";
import { setPreviewProductVideo } from "./actions/previewProductVideo.action";
import { useMemo, useState } from "react";
// import DisableRightClick from "./common/components/disableRightClick";

const App = ({ authorized, firebase, user }) => {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const [previewProduct, setPreviewProduct] = useState([])

  const getRequestBaseApi = async () => {
    setLoading(true)
    const resultPreviewProductVideo = await getPreviewProductVideoAllFetch();
    dispatch(setPreviewProductVideo(resultPreviewProductVideo));
    setPreviewProduct(resultPreviewProductVideo)
    setLoading(false)
  }

  useMemo(() => {
    getRequestBaseApi(); // เรียกใช้เมื่อเริ่มต้นแอป
  }, []); // useEffect นี้ทำงานครั้งเดียวตอน mount

  return (
    <>
      {!loading ?
        <ConfigProvider theme={{ token: themeAntd }}>
          {/* <DisableRightClick /> */}
          <div
            style={
              {
                // userSelect: 'none',
                // MozUserSelect: 'none',
                // WebkitUserSelect: 'none',
                // msUserSelect: 'none'
              }
            }
          >
            <Router>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Home authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/user-manual"
                  element={<UserManual authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/suggestion"
                  element={<Suggestion authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/contact"
                  element={<Contract authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/login"
                  Component={() => (<Login authorized={authorized} firebase={firebase} user={user} />)}
                />
                <Route
                  exact
                  path="/login-detect"
                  Component={() => (<LoginDetect authorized={authorized} firebase={firebase} user={user} />)}
                />
                <Route
                  exact
                  path="/account"
                  Component={() => (<Account authorized={authorized} firebase={firebase} user={user} />)}
                />
                <Route
                  exact
                  path="/course"
                  element={
                    <Course authorized={authorized} firebase={firebase} user={user} previewProduct={previewProduct} />
                  }
                />
                <Route
                  exact
                  path="/course/:id"
                  element={<CourseDetail authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/payment"
                  Component={() => (<Payment authorized={authorized} firebase={firebase} user={user} />)}
                />
                <Route
                  exact
                  path="/classroom"
                  Component={() => (<Classroom authorized={authorized} firebase={firebase} user={user} />
                  )}
                />
                <Route
                  exact
                  path="/classroom-course-study"
                  Component={() => (<ClassroomStudy authorized={authorized} firebase={firebase} user={user} />)}
                />
                <Route
                  exact
                  path="/prediction"
                  element={<Prediction authorized={authorized} firebase={firebase} user={user} previewProduct={previewProduct} />}
                />
                <Route
                  exact
                  path="/prediction/:id"
                  element={<PredictionDetail authorized={authorized} firebase={firebase} user={user} />}
                />

                <Route
                  exact
                  path="/prediction-room"
                  element={<PredictionRoom authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/prediction-open"
                  element={<PredictionOpen authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/ebook"
                  element={<EBook authorized={authorized} firebase={firebase} user={user} previewProduct={previewProduct} />}
                />
                <Route
                  exact
                  path="/ebook/:id"
                  element={<EBookDetail authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/exam"
                  element={<Exam authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route
                  exact
                  path="/blog/:id"
                  element={<Blog authorized={authorized} firebase={firebase} user={user} />}
                />
                <Route exact path="/:id" element={<div>Page not found!</div>} />
                <Route exact path="/register" element={<Register />} />
              </Routes>
            </Router>
          </div>
        </ConfigProvider>
        : []
      }
    </>
  )
}

export default App
