import { useEffect, useState } from "react";
import { Button, Card, Col, Empty, Input, Pagination, Row } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router";
import LoadingPage from "../../../../common/components/loading/page";
import { getEBookAllFetch } from "../../API";
import { serverUrl } from "../../../../constants";
import "./css/index.css";
import "./css/paginate.css";
import { useDimensions } from "../../../../common/mamager/ScreenManager";

const { Search } = Input;

const ProductList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [perPage, setPerPage] = useState(6);

  const navigate = useNavigate();
  const { width } = useDimensions();

  useEffect(() => {
    const getProducts = async () => {
      setIsLoading(true);
      try {
        const resultEBook = await getEBookAllFetch();
        // console.log("getEBookAllFetch : ", resultEBook)
        if (resultEBook) {
          setProducts(resultEBook);
          setTotal(resultEBook.length);
        } else {
          setProducts([]);
          setTotal(0);
        }
      } catch (error) {
        // console.error("Error fetch getProducts:", error)
      }
      setIsLoading(false);
    };

    getProducts();
  }, []);

  const onSearch = (value) => {
    setKeyword(value);
    setCurrentPage(1); // Reset to first page on search
  };

  const onChange = (page, pageSize) => {
    setCurrentPage(page); // Update current page
    setPerPage(pageSize); // Update page size
  };

  // Function to get paginated and filtered data
  const getPaginatedData = () => {
    // Filter products based on the keyword
    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(keyword.toLowerCase())
    );

    // Calculate the start and end index based on the current page and perPage
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    // Return a slice of products based on current page and perPage
    return filteredProducts.slice(startIndex, endIndex);
  };

  return (
    <div className="products">
      <section className="products-list">
        <div className="container">
          <div className="total-and-search">
            <div className="products-total">ผลการค้นหา ({total} รายการ)</div>
            <Search
              placeholder="ชื่อ E-Book"
              onSearch={onSearch}
              enterButton
              style={{ maxWidth: 340 }}
            />
          </div>
          {isLoading && <LoadingPage />}

          <Row
            gutter={[24, 24]}
            style={{
              alignItems: "stretch",
              justifyContent: "center",
            }}
          >
            {getPaginatedData().length > 0 &&
              !isLoading &&
              getPaginatedData().map((data, index) => (
                <Col xs={24} sm={12} lg={8} key={data.ebookId}>
                  <div
                    className="wrapper"
                    onClick={() => {
                      if (width <= 440) {
                        navigate(`/ebook/${data.ebookId.toString()}`);
                        window.scroll(0, 0);
                      }
                    }}
                  >
                    <div className="front-card">
                      <Card
                        hoverable
                        style={{
                          fontSize: "18px",
                          padding: "0",
                          height: 600,
                          border: "2px solid #f0f0f0",
                        }}
                        cover={
                          <img
                            alt={"Witte-bangkok EBook"}
                            src={`${serverUrl}/${data.imgCoverUrl}`}
                            width={354}
                            height={503}
                            style={{
                              maxWidth: "100%",
                              objectFit: "cover",
                            }}
                          />
                        }
                      >
                        <Meta
                          title={
                            <div
                              style={{
                                color: "black",
                                fontSize: "24px",
                                paddingInline: "0px",
                                marginTop: "6px",
                                height: 70,
                              }}
                            >
                              <div className="title">{data.title}</div>
                            </div>
                          }
                          // description={
                          //   <div
                          //     style={{
                          //       color: "black",
                          //       padding: "0px",
                          //       display: "flex",
                          //       flexDirection: "column",
                          //       fontSize: 24,
                          //       marginTop: 4
                          //     }}
                          //   >
                          //     <div className="price">
                          //       ราคา{" "}
                          //       <span
                          //         style={{
                          //           color: `${
                          //             data.discount > 0 ? "black" : "red"
                          //           }`,
                          //           textDecoration:
                          //             data.discount > 0
                          //               ? "line-through"
                          //               : "none",
                          //           textDecorationColor:
                          //             data.discount > 0 ? "red" : "transparent"
                          //         }}
                          //       >
                          //         {new Intl.NumberFormat("en-US").format(
                          //           data.price
                          //         )}
                          //       </span>{" "}
                          //       {data.discount > 0 && (
                          //         <span>
                          //           ลดเหลือ{" "}
                          //           <span style={{ color: "red" }}>
                          //             {new Intl.NumberFormat("en-US").format(
                          //               data.price - data.discount
                          //             )}
                          //           </span>{" "}
                          //         </span>
                          //       )}
                          //       บาท{data.discount === 0 && "/เดือน"}
                          //     </div>
                          //   </div>
                          // }
                        />
                      </Card>
                    </div>
                    {width > 440 && (
                      <div className="overlay">
                        <div
                          className="text"
                          style={{
                            color: "white",
                            padding: 20,
                            display: "flex", // Enable flexbox layout
                            flexDirection: "column", // Arrange items in a column (top to bottom)
                            justifyContent: "space-between", // Distribute space between items (optional)
                            height: "100%", // Ensure the parent div takes full height (if required)
                          }}
                        >
                          <p className="detail-title">{data.title}</p>
                          <p className="detail">{data.subTitle}</p>
                          <Button
                            type="primary"
                            size="large"
                            style={{
                              marginTop: "auto", // Push the button to the bottom
                              width: "fit-content", // Ensure button width adjusts to content size
                            }}
                            onClick={() => {
                              navigate(`/ebook/${data.ebookId.toString()}`);
                              window.scroll(0, 0);
                            }}
                          >
                            รายละเอียดเพิ่มเติม
                          </Button>
                        </div>

                        <img
                          src="./assets/images/page/page_home/our_service_small_back_bg.jpg"
                          alt={"Witte-bangkok Product"}
                          style={{
                            width: "100%",
                            height: 600,
                            objectFit: "cover",
                            zIndex: -1,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            {getPaginatedData().length <= 0 && !isLoading && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ textAlign: "center" }}
              />
            )}
          </Row>
          {getPaginatedData().length > 0 && !isLoading && (
            <Row className="paginate-container">
              <Pagination
                size="small"
                current={currentPage}
                total={total}
                pageSize={perPage}
                defaultPageSize={12}
                onChange={onChange}
                pageSizeOptions={["6", "12", "18", "24", "30"]} // Available page sizes
              />
            </Row>
          )}
        </div>
      </section>
    </div>
  );
};

export default ProductList;
