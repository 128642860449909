/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from "react";
import { Col, Row, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDimensions } from "../../mamager/ScreenManager";
import "../footer/css/index.css";
import { getContactFetch } from "./API";

function Footer() {
  const navigate = useNavigate();
  const { width } = useDimensions();

  const [isModalLineVisible, setIsModalLineVisible] = useState(false);
  const [contactData, setContactData] = useState();

  const getContactData = async () => {
    const res = await getContactFetch();
    setContactData(res);
  };

  useEffect(() => {
    getContactData();
  }, []);

  const handleModalLine = () => {
    setIsModalLineVisible(false);
  };

  function formatPhoneNumber(number) {
    // Remove any non-numeric characters
    const cleanedNumber = number.replace(/\D/g, "");

    // Add the country code for Thailand (+66)
    return `+66${cleanedNumber.substring(1)}`;
  }

  return (
    <footer className="footer">
      <div
        style={{
          display: "grid",
          alignItems: "center",
          // justifyContent: "center",
          backgroundColor: "#262626",
        }}
      >
        <nav
          style={{
            color: "white",
            paddingTop: 60,
            paddingBottom: 60,
            width: "min(100%, 75rem)",
            marginInline: "auto",
          }}
        >
          <Row gutter={[0, 24]}>
            <Col xs={24} md={12} xl={8}>
              <div style={{ display: "grid" }}>
                <div>
                  <div style={{ paddingBottom: 12 }}>
                    <div
                      style={{
                        fontSize: 36,
                        fontWeight: 700,
                        color: "#FEE285",
                        marginLeft: width <= 767 ? 24 : null,
                      }}
                    >
                      อ่านกรรมจากผังดาว
                    </div>
                  </div>

                  <div style={{ paddingBottom: 12 }}>
                    <div style={{ marginLeft: width <= 767 ? 24 : null }}>
                      {contactData?.address}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <div
                style={{
                  display: "grid",
                  placeContent: width <= 767 ? "left" : "center",
                  marginLeft: width <= 767 ? 24 : null,
                }}
              >
                <div style={{ fontWeight: "bolder" }}>การช่วยเหลือ</div>
                <p
                  style={{ color: "#B3B3B3", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/suggestion");
                    window.scroll(0, 0);
                  }}
                >
                  สอบถาม หรือส่งคำเสนอเเนะ
                </p>
                <p
                  style={{ color: "#B3B3B3", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/user-manual");
                    window.scroll(0, 0);
                  }}
                >
                  สอนการใช้งานเว็บไซต์
                </p>
              </div>
            </Col>

            <Col xs={24} md={12} xl={8}>
              <div
                style={{
                  display: "grid",
                  alignItems: "flex-start",
                  paddingLeft: width > 767 ? 0 : 24,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(`tel:${formatPhoneNumber(contactData?.phone)}`);
                  }}
                >
                  <div
                    style={{
                      height: 117,
                      width: 87,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#F3BB02",
                      borderRadius: "16px 0px 0px 16px",
                    }}
                  >
                    <img
                      alt={"Witte-bangkok Call"}
                      src={"/assets/images/footer/phone-call.png"}
                      style={{ height: 60, padding: 10 }}
                    />
                  </div>

                  <div
                    style={{
                      height: 117,
                      minWidth: 180,
                      backgroundColor: "#FFF",
                      borderRadius: "0px 16px 16px 0px",
                    }}
                  >
                    <div style={{ padding: 12 }}>
                      <label
                        style={{
                          fontSize: 24,
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        โทรติดต่อ
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        {contactData?.phone}
                      </label>
                      <br />
                      <label
                        style={{
                          fontSize: 16,
                          // fontWeight: 600,
                          color: "black",
                          cursor: "pointer",
                        }}
                      >
                        เวลาทำการ 9:00 - 17:00 น.
                      </label>
                    </div>
                  </div>
                </div>

                <div className="social-line">
                  <a href={contactData?.facebook} target="_blank">
                    <div className="facebook-icon">
                      <Icon
                        icon="ri:facebook-fill"
                        width="1.2em"
                        height="1.2em"
                        style={{
                          color: "white",
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    </div>
                  </a>

                  <a href={contactData?.youtube} target="_blank">
                    <div className="line-icon">
                      <Icon
                        icon="mdi:youtube"
                        width="50"
                        height="50"
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  </a>
                  <a href={contactData?.line} target="_blank">
                    <div className="line-icon">
                      <Icon
                        icon="mingcute:line-app-fill"
                        style={{
                          color: "white",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    </div>
                  </a>

                  <a href="mailto:WitteBangkok@gmail.com">
                    <div className="line-icon">
                      <Icon
                        icon="clarity:email-solid"
                        width="36"
                        height="36"
                        style={{
                          color: "white",
                        }}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </nav>
      </div>

      <nav
        style={{
          backgroundColor: "#F3BB02",
          color: "black",
          paddingLeft: "5%",
          paddingRight: "5%",
          paddingTop: 15,
          paddingBottom: 15,
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        <label>Copyright © 2025 “witte-bangkok” All Rights Reserved.</label>
      </nav>

      <Modal
        title="ไลน์"
        visible={isModalLineVisible}
        onCancel={handleModalLine}
        // width={1000}
        footer={[]}
      >
        <img
          alt={"Witte-bangkok Line"}
          style={{
            objectFit: "cover",
            width: "100%",
            height: 500,
            borderRadius: 8,
          }}
          src={`./assets/images/contact/line-qrcode.jpg`}
        />
      </Modal>
    </footer>
  );
}

export default Footer;
