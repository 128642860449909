import { httpClient } from "../../../utils/HttpClient"

const getPredictionAllFetch = async () => {
  try {
    const result = await httpClient.get(`prediction/get-prediction-all`)
    if (result.data.isSuccess) {
      return result.data.formData
    } else {
      return null
    }
  } catch (err) {
    // status 404
    return null
  }
}

const getDetectPredictionAwaitingPaymentVerificationFetch = async (value) => {
  try {
  const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
  const queryParams = new URLSearchParams(filteredValue).toString()
      const result = await httpClient.get(`prediction/get-detect-prediction-awaiting-payment-verification?${queryParams}`)
      if (result.data.isSuccess) {
          return result.data.formData
      } else {
          return []
      }
  } catch (err) { // status 404
      return null
  }
}

const getDetectPredictionAlreadyPurchasedFetch = async (value) => {
  try {
  const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
  const queryParams = new URLSearchParams(filteredValue).toString()
      const result = await httpClient.get(`prediction/get-detect-prediction-already-purchased?${queryParams}`)
      if (result.data.isSuccess) {
          return result.data.formData
      } else {
          return []
      }
  } catch (err) { // status 404
      return null
  }
}

const getDetectPredictionExpiredByIdFetch = async (value) => {
  try {
  const filteredValue = Object.fromEntries(Object.entries(value).filter(([_, v]) => v != null && v !== ""))
  const queryParams = new URLSearchParams(filteredValue).toString()
      const result = await httpClient.get(`prediction/get-detect-prediction-expired-by-id?${queryParams}`)
      if (result.data.isSuccess) {
          return result.data.formData
      } else {
          return null
      }
  } catch (err) {}
}

export {
  // get
  getPredictionAllFetch,
  getDetectPredictionAwaitingPaymentVerificationFetch,
  getDetectPredictionAlreadyPurchasedFetch,
  getDetectPredictionExpiredByIdFetch
};
