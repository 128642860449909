import { server } from "../../../constants";
import { httpClient } from "../../../utils/HttpClient";

const getPredictionRoomByIdFetch = async (value) => {
  try {
    const result = await httpClient.get(
      `prediction_room/get-prediction-room-by-id?uid=${value.uid}`
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const getDetectFilledInPersonalInfoFetch = async (uid) => {
  try {
    const result = await httpClient.get(`user/detect-filled-in-personal-info?uid=${uid}`);
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {}
};

const updateUserInfoByIdFetch = async (value) => {
  // Done
  try {
    const result = await httpClient.put(`user/update-user-info-by-id`, {value});
    return result.data;
  } catch (err) {
    // status 404
    return null;
  }
};
export {
  // get
  getPredictionRoomByIdFetch,
  getDetectFilledInPersonalInfoFetch,

  // insert

  // update
  updateUserInfoByIdFetch,

  // delete
  
};
