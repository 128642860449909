/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from "react";
import { Row, Col, Pagination, Empty } from "antd";
import ExamRoomItem from "./ExamRoomItem";
import { useDimensions } from "../../../../common/mamager/ScreenManager";

export default function ExamRoomBoard(props) {
  const { width } = useDimensions();
  const [examRoom, setExamRoom] = useState({
    data: [],
    pageNumber: 0,
  });

  const [page, setPage] = useState({
    minValue: 0,
    maxValue: 4,
  });

  const handleChange = (value) => {
    if (value <= 1) {
      setPage({
        minValue: 0,
        maxValue: 4,
      });
    } else {
      setPage({
        minValue: page.maxValue,
        maxValue: value * 4,
      });
    }
    window.scrollTo(0, 0);
  };

  function getCourseFound() {
    return (
      <div style={{ paddingTop: 30, paddingBottom: 60 }}>
        <Empty
          imageStyle={{
            height: 80,
          }}
          style={{ width: "100%" }}
          description={<span style={{ color: "gray" }}>ไม่พบรายการ</span>}
        ></Empty>
      </div>
    );
  }

  useEffect(() => {
    let fillPaymentOrderStatus = props.examRoom?.filter(
      (fill) => fill?.payment_order?.paymentStatusId === 1
    );
    setExamRoom({
      data: fillPaymentOrderStatus,
      pageNumber: 0,
    });
  }, [props.examRoom]);

  return (
    <Row gutter={[0, 12]} style={{ paddingInline: width < 600 ? 0 : 37 }}>
      <Col span={24}>
        <label style={{ fontWeight: 600 }}>ใบประกาศนียบัตร</label>
      </Col>
      {examRoom.data.length > 0 ? (
        <>
          <Col span={24}>
            <Row>
              {examRoom.data &&
                examRoom.data.length > 0 &&
                examRoom.data
                  .slice(page.minValue, page.maxValue)
                  .map((val, index) => (
                    <>
                      <ExamRoomItem
                        key={index}
                        item={val}
                        index={index + 1}
                        user={props.user}
                      />
                    </>
                  ))}
            </Row>
          </Col>

          <Col span={24} style={{ paddingBottom: 15 }}>
            <center>
              <Pagination
                defaultCurrent={1}
                defaultPageSize={4}
                onChange={handleChange}
                total={examRoom.data.length}
              />
            </center>
          </Col>
        </>
      ) : (
        <Col span={24}>{getCourseFound()}</Col>
      )}
    </Row>
  );
}
